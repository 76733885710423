import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import Transition from '../utils/Transition';

import USFlag from './../images/usa.svg';
import PTFlag from './../images/brazil.svg';
import ESFlag from './../images/svg/spain-flag.svg';
import {useTranslation} from "react-i18next";
import {LanguageIcon} from "@heroicons/react/24/outline";

function DropdownLanguage({
                            align
                          }) {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en'); // Track selected language


  const trigger = useRef(null);
  const dropdown = useRef(null);


  // close on click outside
  useEffect(() => {
    const clickHandler = ({target}) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({keyCode}) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const {i18n} = useTranslation();

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
    i18n.changeLanguage(selectedOption);
  };

  return (
    <>
      <style>
        {`
          .highlight {
            background-color: bg-gray-100 dark:bg-gray-500;
          }
        `}
      </style>
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className={`w-8 h-8 flex items-center justify-center hover:bg-gray-100 lg:hover:bg-gray-200 dark:hover:bg-gray-700/50 dark:lg:hover:bg-gray-800 rounded-full ${dropdownOpen && 'bg-gray-200 dark:bg-gray-800'}`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">Language</span>
        <LanguageIcon style={{width: '18px', height: '18px'}}/>
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full w-36 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700/60 py-1.5 rounded-lg shadow-lg overflow-hidden mt-1 ${align === 'right' ? 'right-0' : 'left-0'}`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="text-xs font-semibold text-gray-400 dark:text-gray-500 uppercase pt-1.5 pb-2 px-3">
            Language
          </div>
          <ul className="w-full">
            <li>
              <Link
                className={`font-medium text-sm text-violet-500 hover:text-violet-600 dark:hover:text-violet-400 flex
                items-center py-1 px-3 ${selectedLanguage === 'en' ? 'font-bold background-color: bg-gray-200 dark:bg-gray-900' : ''}`}
                to="#0"
                onClick={() => {
                  handleLanguageChange('en')
                  setDropdownOpen(!dropdownOpen)
                }
                }
              >
                <img src={USFlag} alt="USA flag"/>
                <span>English</span>
              </Link>
            </li>
            <li>
              <Link
                className={`font-medium text-sm text-violet-500 hover:text-violet-600 dark:hover:text-violet-400 flex
                items-center py-1 px-3 ${selectedLanguage === 'pt' ? 'font-bold background-color: bg-gray-200 dark:bg-gray-900' : ''}`}
                to="#0"
                onClick={() => {
                  handleLanguageChange('pt')
                  setDropdownOpen(!dropdownOpen)
                }
                }
              >
                <img src={PTFlag} alt="Brazil flag"/>
                <span>Português</span>
              </Link>
            </li>
            <li>
              <Link
                className={`font-medium text-sm text-violet-500 hover:text-violet-600 dark:hover:text-violet-400 flex
                items-center py-1 px-3 ${selectedLanguage === 'es' ? 'font-bold background-color: bg-gray-200 dark:bg-gray-900' : ''}`}
                to="#0"
                onClick={() => {
                  handleLanguageChange('es')
                  setDropdownOpen(!dropdownOpen)
                }
                }
              >
                <img src={ESFlag} alt="Brazil flag"/>
                <span>Español</span>
              </Link>
            </li>

          </ul>
        </div>
      </Transition>
    </div>
    </>
  )
}

export default DropdownLanguage;