import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useFormContext} from '../../util/FormContext';
import {useTranslation} from "react-i18next";
import log from "../../util/logger";
import CompanyCreate from "../../api/model/CompanyCreate";
import api from "../../api";

const Step3Form = forwardRef((props, ref) => {
  log.debug("------- Step3Form.js - Step3Form called -------");
  const {t, i18n} = useTranslation();
  const {formData, updateFormData} = useFormContext();

  // State for form errors
  const [errors, setErrors] = useState({});

  // Effect to scroll to the top when the component is rendered
  useEffect(() => {
    log.debug("formData in Step3Form:", formData);
      window.scrollTo(0, 0);

    if (!formData.company_id) {
        createCompany()
        updateFormData({
          ...formData,
          isCompanyCreated: true
        });
      } else {
      log.debug("Company already created");
      }

    }, []
  )
  ;


  const createSocialMediaPlatforms = async (company_id) => {
    try {
      // Fetch the list of platforms
      const listPlatforms = await api.listSocialNetworksPlatforms();
      log.debug('Step3Form: listPlatforms:', listPlatforms);

      // Iterate over formData.socials entries
      for (const [social, profile_url] of Object.entries(formData.socials)) {
        if (profile_url) {
          // Find the platform by name (case insensitive)
          const platform = listPlatforms.find(
            (platform) => platform.name.toLowerCase() === social.toLowerCase()
          );

          if (platform) {
            const platform_id = platform.platform_id;

            // Create the social network entry
            try {
              const response = await api.createSocialNetwork(company_id, platform_id, profile_url);
              log.debug('Step4Form: createSocialMediaPlatform response:', response);
              log.debug('Social Media Platform Created:', response);
            } catch (error) {
              log.error('Failed to create social media platform:', error);
            }
          }
        }
      }
    } catch (error) {
      log.error('Failed to list social network platforms:', error);
    }
  };


  const createCompany = () => {
    log.debug("Step3Form: createCompany called");
    let company = new CompanyCreate()
    company.website_url = formData.website
    company.name = formData.companyName
    company.description = formData.companyHeadline
    company.industry = formData.industry
    company.logo_url = formData.companyFavIcon
    company.registration_number = Math.floor(Math.random() * 1000000000).toString()
    company.address = formData.street
    company.city = formData.city
    company.state = formData.state
    company.postal_code = formData.postal_code
    company.country = formData.country
    company.contact_email = formData.companyEmail || null
    company.contact_phone = formData.phoneNumbers
    company.subscription_plan = "free"
    company.scrape_data = JSON.stringify(formData?.original_response) || null

    log.debug('Step3Form: createCompany company:', company);

    api.createNewCompany(company).then((response) => {
      log.debug('Step4Form: createCompany response:', response);
      if (response && response.created_at) {
        log.debug("Company successfully created with created_at:", response);
        const {id, ...rest} = response;
        updateFormData({
          ...rest,
          company_id: id
        })

        createSocialMediaPlatforms(id)

      } else {
        log.error("Company creation failed or response invalid.");
        updateFormData({
          ...formData,
          isCompanyCreated: false
        });
        return false
      }
    }).catch((error) => {
      log.error("Error creating company:", error);
    });
  };

  // Generic handle change function for all inputs
  const handleChange = (e) => {
    const {name, value} = e.target;
    log.debug("[HANDLECHANGE] name:", name, "value:", value);
    // Update formData directly
    updateFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // List of required fields
    const requiredFields = ['companyName', 'city', 'state', 'postalCode', 'street', 'country'];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = true;
        isValid = false;
        log.debug("validateForm: field:", field, " formData[field]:", formData[field]);
      }
    });

    log.debug("validateForm: newErrors:", newErrors);
    setErrors(newErrors);
    return isValid;
  };

  // Expose validation and submission functions to parent component
  useImperativeHandle(ref, () => ({
    validateForm,
    submitForm: () => {
      if (validateForm()) {
        log.debug("Form is valid, submitting data:", formData);
      }
    },
  }));

  return (
    <div className="sm:px-4 py-8">
      <div className="max-w-xl mx-auto">
        <h1 className="text-3xl text-gray-800 dark:text-gray-100 font-bold mb-2">
          {t('onboarding.step3.aditionalinformation')}
        </h1>
        <p className="text-gray-600 dark:text-gray-400 mb-6">
          {t('onboarding.step3.aditionalinformationdescription')}
        </p>
        <form>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="companyEmail">
                {t("onboarding.step3.email")}
              </label>
              <input
                id="companyEmail"
                name="companyEmail"
                className={`form-input w-full text-black ${errors.companyEmail ? 'border-red-500' : ''}`}
                type="text"
                value={formData.companyEmail || ''}
                onChange={handleChange}
              />
              {errors.companyEmail && <p className="text-red-500">{t('onboarding.step3.EmailError')}</p>}
            </div>
            <div>
              <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="phoneNumbers">
                {t("onboarding.step3.phoneNumber")}
              </label>
              <input
                id="phoneNumbers"
                name="phoneNumbers"
                className={`form-input w-full text-black ${errors.phoneNumbers ? 'border-red-500' : ''}`}
                type="text"
                value={formData.phoneNumbers || ''}
                onChange={handleChange}
              />
              {errors.phoneNumbers && <p className="text-red-500">{t('onboarding.step3.PhoneNumberError')}</p>}
            </div>
            {/* Street Address */}
            <div>
              <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="street">
                {t("onboarding.step3.address")} <span className="text-red-500">*</span>
              </label>
              <input
                id="street"
                name="street"
                className={`form-input w-full text-black ${errors.street ? 'border-red-500' : ''}`}
                type="text"
                value={formData.street || ''}
                onChange={handleChange}
              />
              {errors.street && <p className="text-red-500">{t('onboarding.step3.AddressError')}</p>}
            </div>
            {/* City and Postal Code */}
            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="city">
                  {t('onboarding.step3.city')} <span className="text-red-500">*</span>
                </label>
                <input
                  id="city"
                  name="city"
                  className={`form-input w-full text-black ${errors.city ? 'border-red-500' : ''}`}
                  type="text"
                  value={formData.city || ''}
                  onChange={handleChange}
                />
                {errors.city && <p className="text-red-500">{t('onboarding.step3.CityError')}</p>}
              </div>
              <div className="flex-1">
                <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="state">
                  {t('onboarding.step3.state')} <span className="text-red-500">*</span>
                </label>
                <input
                  id="state"
                  name="state"
                  className={`form-input w-full text-black ${errors.state ? 'border-red-500' : ''}`}
                  type="text"
                  value={formData.state || ''}
                  onChange={handleChange}
                />
                {errors.state && <p className="text-red-500">{t('onboarding.step3.StateError')}</p>}
              </div>

            </div>

            {/* Country */}
            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="postalCode">
                  {t('onboarding.step3.postalCode')} <span className="text-red-500">*</span>
                </label>
                <input
                  id="postalCode"
                  name="postalCode"
                  className={`form-input w-full text-black ${errors.postalCode ? 'border-red-500' : ''}`}
                  type="text"
                  value={formData.postalCode || ''}
                  onChange={handleChange}
                />
                {errors.postalCode && <p className="text-red-500">{t('onboarding.step3.PostalCodeError')}</p>}
              </div>
              <div className="flex-1">
                <div>
                  <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="country">
                    {t("onboarding.step3.country")} <span className="text-red-500">*</span>
                  </label>
                  <select
                    id="country"
                    name="country"
                    className={`form-select w-full text-black ${errors.country ? 'border-red-500' : ''}`}
                    value={formData.country || 'United States'}
                    onChange={handleChange}
                  >
                    <option value="Argentina">Argentina</option>
                    <option value="Australia">Australia</option>
                    <option value="Brazil">Brazil</option>
                    <option value="Canada">Canada</option>
                    <option value="Chile">Chile</option>
                    <option value="Colombia">Colombia</option>
                    <option value="France">France</option>
                    <option value="Germany">Germany</option>
                    <option value="India">India</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Japan">Japan</option>
                    <option value="Mexico">Mexico</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Singapore">Singapore</option>
                    <option value="South Africa">South Africa</option>
                    <option value="Spain">Spain</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>

                  </select>
                  {errors.country && <p className="text-red-500">Please select a country.</p>}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
});

export default Step3Form;
