import React, {useEffect, useRef, useState} from 'react';
import ContentTableItem from "./ContentTableItem";
import {useTranslation} from "react-i18next";
import log from "../../util/logger";

function ContentTable({
                        selectedItems,
                        contentIdeas,
                        updateModal,
                        onRemove,
                        socialPlataformList
                      }) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };


  const invoices = [
    {
      id: '0',
      invoice: '#123567',
      total: '$129.00',
      status: 'Overdue',
      customer: 'Dominik Lamakani',
      issueddate: '22/07/2024',
      paiddate: '-',
      type: 'Subscription',
    },
    {
      id: '1',
      invoice: '#779912',
      total: '$59.00',
      status: 'Paid',
      customer: 'Mark Cameron',
      issueddate: '19/07/2024',
      paiddate: '20/07/2024',
      type: 'Subscription',
    },
    {
      id: '2',
      invoice: '#889924',
      total: '$89.00',
      status: 'Paid',
      customer: 'Sergio Gonnelli',
      issueddate: '17/07/2024',
      paiddate: '19/07/2024',
      type: 'One-time',
    },
    {
      id: '3',
      invoice: '#897726',
      total: '$129.00',
      status: 'Due',
      customer: 'Manuel Garbaya',
      issueddate: '04/07/2024',
      paiddate: '-',
      type: 'Subscription',
    },
    {
      id: '4',
      invoice: '#123567',
      total: '$129.00',
      status: 'Due',
      customer: 'Cool Robot',
      issueddate: '04/07/2024',
      paiddate: '-',
      type: 'Subscription',
    },
    {
      id: '5',
      invoice: '#896644',
      total: '$129.00',
      status: 'Paid',
      customer: 'Mark Cameron',
      issueddate: '04/07/2024',
      paiddate: '09/07/2024',
      type: 'One-time',
    },
    {
      id: '6',
      invoice: '#136988',
      total: '$69.00',
      status: 'Paid',
      customer: 'Glenn Thomas',
      issueddate: '01/07/2024',
      paiddate: '01/07/2024',
      type: 'One-time',
    },
    {
      id: '7',
      invoice: '#442206',
      total: '$129.00',
      status: 'Overdue',
      customer: 'Dominik Lamakani',
      issueddate: '22/06/2024',
      paiddate: '-',
      type: 'Subscription',
    },
    {
      id: '8',
      invoice: '#764321',
      total: '$89.00',
      status: 'Paid',
      customer: 'Brian Halligan',
      issueddate: '21/06/2024',
      paiddate: '29/06/2024',
      type: 'One-time',
    },
    {
      id: '9',
      invoice: '#908764',
      total: '$129.00',
      status: 'Due',
      customer: 'Carolyn McNeail',
      issueddate: '17/06/2024',
      paiddate: '-',
      type: 'Subscription',
    }
  ];

  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  const socialNetworks = useRef(null);

  useEffect(() => {
    // setList(invoices);
    setList(contentIdeas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentIdeas]);

//     const removeContentIdea = (id) => {
//   log.debug('[CompanyContent] Removing content idea: ', id);
//   setContentIdeas((prevIdeas) => {
//     log.debug('[CompanyContent] Current content ideas: ', prevIdeas);
//     const updatedIdeas = prevIdeas.filter(item => item.id !== id);
//     log.debug('[CompanyContent] Updated content ideas: ', updatedIdeas);
//     return updatedIdeas;
//   });
// };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map(li => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = e => {
    const {id, checked} = e.target;
    // log.debug('selected id:', id);
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };


  // const socialNetworks = ['facebook', 'twitter', 'instagram', 'linkedin', 'reddit', 'tiktok', 'whatsapp'];
  // ['facebook', 'twitter', 'instagram', 'whatsapp'];
  if (socialPlataformList !== undefined && socialPlataformList !== null && socialPlataformList.length > 0) {
    socialNetworks.current = socialPlataformList;
  } else {
    socialNetworks.current = ['facebook', 'twitter', 'instagram', 'whatsapp'];
  }

  const getRandomSocialNetwork = () => socialNetworks.current[Math.floor(Math.random() * socialNetworks.current.length)];

  log.debug('ContentTable: socialNetworks:', socialNetworks.current);

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  return (

    <div className="bg-white dark:bg-gray-800 shadow-sm rounded-xl relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-gray-800 dark:text-gray-100">{t("company_content.ideas")} <span
          className="text-gray-400 dark:text-gray-500 font-medium">
            ({list.length})
          </span></h2>
      </header>
      <div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-gray-300">
            {/* Table header */}
            <thead
              className="text-xs font-semibold uppercase text-gray-500 dark:text-gray-400 bg-gray-50 dark:bg-gray-900/20 border-t border-b border-gray-100 dark:border-gray-700/60">
            <tr>
              <th className="px-2 px-3 py-3 whitespace-nowrap w-px">
                <div className="flex items-center">
                  <label className="inline-flex">
                    <span className="sr-only">{t("select_all")}</span>
                    <input className="form-checkbox" type="checkbox" checked={selectAll} onChange={handleSelectAll}/>
                  </label>
                </div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">
                  {t("dashboard.tab.content")}
                </div>
              </th>
              {/*<th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">*/}
              {/*  <div className="font-semibold text-left">Summary</div>*/}
              {/*</th>*/}
              {/*<th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">*/}
              {/*  <div className="font-semibold text-left">Status</div>*/}
              {/*</th>*/}
            </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100 dark:divide-gray-700/60">
            {
              // list.map(content => {
              //   return (
              //     <ContentTableItem
              //       key={invoice.id}
              //       id={invoice.id}
              //       invoice={invoice.invoice}
              //       total={invoice.total}
              //       status={invoice.status}
              //       customer={invoice.customer}
              //       issueddate={invoice.issueddate}
              //       // paiddate={invoice.paiddate}
              //       type={invoice.type}
              //       handleClick={handleClick}
              //       isChecked={isCheck.includes(invoice.id)}
              //     />
              //   )
              // })

              list.map(content => {
                return (
                  <ContentTableItem
                    key={content.id}
                    id={content.id}
                    emoji={content.emoji}
                    idea={content.idea}
                    summary={content.summary}
                    socialNetwork={content.social_network_recommended}
                    status={content.status}
                    customer={content.customer}
                    issueddate={content.issueddate}
                    type={content.content_type}
                    handleClick={handleClick}
                    isChecked={isCheck.includes(content.id)}
                    onRemove={() => onRemove(content.id)}
                    updateModal={() => updateModal(content.id)}
                  />
                )
              })
            }
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}

export default ContentTable;
