import React, {useEffect, useRef, useState} from "react";
import {useAuth} from "../../util/auth";
import {useTranslation} from "react-i18next";
import log from "../../util/logger";
import ContentMixCard from "./ContentMixCard";
import ContentStrategyForm from "./ContentStrategyForm";
import LoadingAnimation from "../LoadingAnimation";
import {useContentMix, usePostGenerator} from "../../util/hooks";
import {cleanData, getFullLanguageName, getSocialPlatformNames, removeLocalStorageData} from "../../util/util";
import {useQueryClient} from "@tanstack/react-query";
import ModalBasic from "../ModalBasic";

import murmurhash from 'murmurhash';

// import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FaRegClipboard} from "react-icons/fa";
import MarkdownEditor from "../MarkdownEditor";
import ContentTable from "./ContentTable";
import {AnimatePresence} from "framer-motion";
import Tip from "../Tip";


function CompanyContent(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  const auth = useAuth();

  const queryClient = useQueryClient();

  const [basicModalOpen, setBasicModalOpen] = useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [enableContentStrategyForm, setEnableContentStrategyForm] = React.useState(false);
  const [contentStrategy, setContentStrategy] = React.useState(null);
  const [enableContentMix, setEnableContentMix] = React.useState(false);
  const [contentIdeas, setContentIdeas] = React.useState([]);
  const [contentMixDescription, setContentMixDescription] = React.useState("");
  const [enableGetContentMix, setEnableGetContentMix] = React.useState(false);
  const [modalData, setModalData] = useState(null);
  const [enablePostGenerator, setEnablePostGenerator] = useState(true);

  const [generatePostData, setGeneratePostData] = useState(null);
  const [generatePostIsLoading, setGeneratePostIsLoading] = useState(false);
  const [generatePostError, setGeneratePostError] = useState(null);
  const [generatePostIsSuccess, setGeneratePostIsSuccess] = useState(false);
  // const [actualIndex.current, setactualIndex.current] = useState(0);
  const [dataCopied, setDataCopied] = useState(false);
  const actualIndex = useRef(0)

  const dataItem = useRef(null);

  const socialPlatformList = getSocialPlatformNames(auth.defaultCompany);

  const [selectedItems, setSelectedItems] = useState([]);


  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  let strategyProfile = null

  const generateHashes = (array, URL = "www.sunokrom.com") => {
    return array.map((item) => {
      const combinedText = `${item.idea}${item.summary}${URL}`;
      const hash1 = murmurhash.v3(combinedText);
      const hash2 = murmurhash.v3(combinedText, 42);
      const id = `${hash1.toString(16)}${hash2.toString(16)}`
      return {...item, id};
    });
  };


  useEffect(() => {
    if (basicModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [basicModalOpen]);

  const getContentItem = (id = actualIndex.current) => {
    return contentIdeas.find(item => item.id === id);
  }

  const updateModal = (id) => {
    actualIndex.current = id
    // const item = contentIdeas.find(item => item.id === id)
    const item = getContentItem(id)
    log.debug('[CompanyContent] updateModal index received', id);
    log.debug('[CompanyContent] updateModal ...contentIdeas[index] ', item);

    // log.debug('[CompanyContent] ...contentIdeas[index] ', contentIdeas[actualIndex.current]);
    setModalData(item);
    setBasicModalOpen(true);

  }


  const updateStrategy = (strategy) => {
    log.debug('[CompanyContent] Updating Content Strategy: ', strategy);
    setIsLoading(true);
    strategyProfile = strategy;
    setEnableGetContentMix(true);
    // setContentStrategy(strategy);

    // Call API to update the company content strategy
  }

  const removeContentIdea = (id) => {
    log.debug('[CompanyContent] Removing content idea: ', id);
    setContentIdeas((prevIdeas) => {
      log.debug('[CompanyContent] Current content ideas: ', prevIdeas);
      const updatedIdeas = prevIdeas.filter(item => item.id !== id);
      log.debug('[CompanyContent] Updated content ideas: ', updatedIdeas);
      return updatedIdeas;
    });
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const updateGeneratePostData = (data) => {
    log.debug("Updating generatePostData: ", data);
    setGeneratePostData({id: getContentItem(), data: data});
    setBasicModalOpen(true);
    // if (data?.stopPropagation) data.stopPropagation();   setGeneratePostData(data);
  }


  // REACT-QUERY
  // ############## AI CONTENT MIX PULL ##############
  const {
    data: contentMixData,
    isLoading: contentMixIsLoading,
    error: contentMixIsError,
    isSuccess: contentMixIsSuccess,
    refetch: useContentMixRefetch
  } = useContentMix(
    {
      ...auth.defaultCompany,
      content_profile: strategyProfile,
      location: `${auth.defaultCompany.address} - 
      ${auth.defaultCompany.city}, ${auth.defaultCompany.state},
       ${auth.defaultCompany.postal_code}, ${auth.defaultCompany.country}`,
      socialPlataformList: socialPlatformList,
    },
    getFullLanguageName(i18n.language),
    (resp) => { /* onSuccess callback */
      log.debug("useContentMix Success", resp);
    },
    (err) => { /* onError callback */
      log.error("useContentMix error", err);
    },
    false, // isSuccess flag (unused)
    enableGetContentMix // enable flag
  );

  // REACT-QUERY
  // ############## AI POST GENERATOR ##############
  const {
    data: postGeneratorData,
    isLoading: postGeneratorIsLoading,
    error: postGeneratorIsError,
    isSuccess: postGeneratorIsSuccess,
    refetch: postGeneratorRefetch,
    isStale
  } = usePostGenerator(
    {
      ...auth.defaultCompany,
      post_idea: getContentItem()?.emoji + ' ' + getContentItem()?.idea + ' ' + getContentItem()?.summary,
      social_network_recommended: getContentItem()?.social_network_recommended,
      ideaHash: getContentItem()?.id,
      content_profile: strategyProfile,
      location: `${auth.defaultCompany.address} - 
      ${auth.defaultCompany.city}, ${auth.defaultCompany.state},
       ${auth.defaultCompany.postal_code}, ${auth.defaultCompany.country}`,
    },
    getFullLanguageName(i18n.language),
    (resp) => { /* onSuccess callback */
      log.debug("usePostGenerator Respose: ", resp);

      let data = resp?.data?.data

      log.debug("usePostGenerator data: ", data);

      setGeneratePostData({
        id: getContentItem(),
        data: data
      });
      setGeneratePostIsLoading(false);
      setGeneratePostIsSuccess(true);
    },
    (err) => { /* onError callback */
      log.error("useContentMix error", err);
    },
    false, // isSuccess flag (unused)
    generatePostIsLoading, // enable flag
  );

  const getNewPost = async (id) => {
    log.debug("Generating new post called");
    dataItem.current = contentIdeas.find(item => item.id === id)
    log.debug("Content Idea to generate post: ", dataItem);
    setGeneratePostIsLoading(true);

    const queryID = `${auth.defaultCompany.website_url}-post-${id}`;
    log.debug("getNewPost usePostGenerator queryID: ", queryID);
    const cachedData = queryClient.getQueryData(['usePostGenerator', queryID]);

    if (cachedData) {
      log.debug("Using cached data for post generation");
      const resp = {data: {data: cachedData}}; // Mock response structure
      log.debug("usePostGenerator Response: ", resp);

      const data = resp?.data?.data?.data;

      log.debug("usePostGenerator data: ", data);

      setGeneratePostData(data);
      setGeneratePostIsLoading(false);
      setGeneratePostIsSuccess(true);
    } else if (isStale) {
      log.debug("Data is stale, refetching...");
      postGeneratorRefetch();
    } else {
      log.debug("No cached data found, refetching...");
      postGeneratorRefetch();
    }
  }


  const ContentMixCleanUp = () => {
    log.info("Cleaning up content mix data");
    queryClient.removeQueries(['useContentMix', auth.defaultCompany.website_url]);
    try {
      const clean_localStorage = removeLocalStorageData(`useContentMix-${auth.defaultCompany.website_url}`);
      log.warn("Cleaned Local Storage: ", clean_localStorage)
    } catch (e) {
      log.error("Cleaned Local Storage error ", e)
    }

    log.info("Refetching content mix data");
    // useContentMixRefetch();
  }

  useEffect(() => {
    if (auth.defaultCompany) {
      log.debug('[CompanyContent] Default Company: ', auth.defaultCompany);
      if (auth.defaultCompany.content_mix_profile && auth.defaultCompany.content_mix_profile !== "" && auth.defaultCompany.content_mix_profile !== null && auth.defaultCompany.content_mix_profile !== undefined) {
        log.debug("[CompanyContent] Found content mix profile");
        log.debug('[CompanyContent] Content Strategy: ', auth.defaultCompany.content_mix_profile);
        setContentStrategy(auth.defaultCompany.contentStrategy);
        setEnableContentStrategyForm(false);
        setIsLoading(true);
        setEnableContentMix(false);
        setEnableGetContentMix(true);

      } else {
        log.debug('[CompanyContent] Company Content Strategy not found, loading form');
        setEnableContentStrategyForm(true);
        log.warn("Query removed: ", ['useContentMix', auth.defaultCompany.website_url]);
        ContentMixCleanUp();

        setIsLoading(false);
      }
    } else {
      log.error('[CompanyContent] Default Company not found');
    }

    log.debug("enableContentStrategyForm", enableContentStrategyForm);
    log.debug("enableContentMix", enableContentMix);
    log.debug("contentMixIsSuccess", contentMixIsSuccess);
  }, [])

  useEffect(() => {
    log.debug("contentMixData", contentMixData);
    log.debug("contentMixIsSuccess", contentMixIsSuccess);
    if (contentMixIsSuccess && contentMixData && contentMixData.data && contentMixData.data.content_ideas && contentMixData.data.content_ideas.length > 0) {
      log.debug("contentMixData.content_ideas adding ID ");
      // Add ID to each content idea
      const hashed_content_ideas = generateHashes(contentMixData.data.content_ideas, auth.defaultCompany.website_url);
      log.debug("contentMixData.content_ideas: after hash ", hashed_content_ideas);
      const shuffledContentIdeas = shuffleArray([...hashed_content_ideas]);

      setContentMixDescription(contentMixData.data.content_mix.description);
      log.debug("contentMixData.data.content_mix.description: ", contentMixData.data.content_mix.description);

      setContentIdeas(shuffledContentIdeas);
      setIsLoading(false)
      setEnableContentMix(true);
    } else {
      log.error("contentMixData No content ideas found");
      ContentMixCleanUp();

    }

  }, [contentMixData, contentMixIsSuccess]);

  useEffect(() => {
    log.debug("useEffect basicModalOpen changed: ", basicModalOpen);

    if (basicModalOpen) {
      // For tests
      // setGeneratePostData("\"🏠 Pequenos espaços, grandes possibilidades! Você sabia que é possível transformar completamente seu ambiente com móveis planejados inteligentes? \\\\n\\\\n✨ Apresentamos 8 soluções criativas para otimizar cada cantinho da sua casa:\\\\n\\\\n- Camas com gavetas embutidas\\\\n- Mesas retráteis de parede\\\\n- Armários multiuso\\\\n- Nichos organizadores\\\\n- Painéis multifuncionais\\\\n- Bancadas extensíveis\\\\n- Gaveteiros deslizantes\\\\n- Estantes modulares\\\\n\\\\n🎯 Quer conhecer mais sobre essas soluções e ver como podemos transformar seu espaço? Entre em contato conosco! \\\\n\\\\n📞 Agende uma visita: (11) 4067-1911\\\\n⚡ Orçamento sem compromisso!\\\\n\\\\n#MoveisPlayejados #MarcenariaDoElton #DecoracaoInteligente #AmbientesPlanejados\"");
      log.debug("Opening Basic Modal, generatePostData:", generatePostData);

      // If generatePostData is null, then set GeneratePostIsLoading to false
      if (generatePostData === null || generatePostData === undefined) {
        setGeneratePostIsLoading(false)
      }
      setDataCopied(false);

      setGeneratePostIsSuccess(false);

      // Moved to the function updateModal
      getNewPost(actualIndex.current);

    } else {
      log.debug("Cleaning up generatePostData");
      setGeneratePostData(null);
      setGeneratePostIsLoading(false)
    }

  }, [basicModalOpen]);

  if (isLoading) {
    return (
      <LoadingAnimation text={"✨" + t("dashboard.loading_ai_content")}/>
    )
  }


  return (
    <div className="flex overflow-hidden h-full w-full mb-2">

      {enableContentStrategyForm && (
        <ContentStrategyForm updateStrategy={updateStrategy}/>
      )}

      {contentMixIsSuccess && (
        <div className="my-2 h-full w-full">

          {/* ####### CONTENT MIX ####### */}
          <div className="flex flex-wrap max-h-90 overflow-hidden w-full">
            <ContentMixCard
              contentStrategy={contentMixData}
              description={contentMixDescription}
            />
          </div>

          {/* ####### CONTENT IDEAS HEADER ####### */}
          <div
            className="flex flex-col space-y-2 overflow-y-auto max-h-[calc(100vh-4rem) pb-8 mt-8"> {/* max-h-[calc(100vh-1rem)] */}
            <div className="sticky  bg-white dark:bg-gray-900"> {/* top-0 z-10 */}
              <h2
                className="text-xl font-semibold text-gray-800 dark:text-gray-100   ">
                {t('company_content.ideas')}
              </h2>
              <h5 className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                {t('company_content.ideas_description')}
              </h5>
            </div>


            {/* ####### CONTENT IDEAS LIST ####### */}
            {/*<div className="flex flex-col min-h-screen h-full relative w-full mb-8">*/}
            {/*  <div className="flex flex-col space-y-2 pb-6 grow">*/}
            {/*    <AnimatePresence mode="popLayout">*/}
            {/*      {contentIdeas.map((idea, index) => (*/}
            {/*        <ContentIdeaCard*/}
            {/*          key={idea.idea}*/}
            {/*          idea={idea.idea}*/}
            {/*          summary={idea.summary}*/}
            {/*          content_type={idea.content_type}*/}
            {/*          idea_emoji={idea.emoji}*/}
            {/*          onRemove={() => removeContentIdea(index)}*/}
            {/*          updateModal={updateModal}*/}
            {/*          index={index}*/}
            {/*        />*/}
            {/*      ))}*/}
            {/*    </AnimatePresence>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <AnimatePresence mode="popLayout">
              <ContentTable
                contentIdeas={contentIdeas}
                selectedItems={handleSelectedItems}
                onRemove={(id) => removeContentIdea(id)}
                updateModal={updateModal}
                socialPlataformList={socialPlatformList}
              />
            </AnimatePresence>

            {/* ======= ======= ======= ======= ======= ======= ======= ======= ======= =======  */}
            {/* ####### CONTENT IDEA MODAL ####### */}
            <div className="relative  w-full h-full">
              <ModalBasic id="basic-modal"
                          modalOpen={basicModalOpen}
                          setModalOpen={setBasicModalOpen}
                          size="expand"
                          title={modalData?.emoji + " " + modalData?.idea}
              >
                <div className="px-5 pt-4 pb-1 w-full">
                  <div className="text-sm">
                    <div className="flex items-center space-x-2">
                      <div className="font-medium text-gray-800 dark:text-gray-100 mb-2">
                      </div>
                    </div>

                    <div className="space-y-2">
                      {enablePostGenerator && generatePostIsLoading && (
                        <LoadingAnimation/>
                      )}


                      {generatePostIsSuccess && generatePostData ? (
                        <div className="flex flex-col space-y-2 w-full min-h-[100px] ">

                          {modalData?.social_network_recommended && (
                            <h4 className="text mt-0 font-semibold text-gray-800 dark:text-gray-100">
                              {t("company_content.content_recommended_for")} <i
                              className="font-black">{modalData?.social_network_recommended}</i>
                            </h4>
                          )}

                          {/* ####### RENDER CONTENT ####### */}
                          <div
                            className="prose text-base font-medium prose-indigo dark:prose-invert rounded-b-md bg-gray-100 dark:bg-gray-900 p-1 w-full max-w-full h-full ">
                            {/*<MarkdownRenderer*/}
                            {/*  content={cleanData(generatePostData)}/>*/}
                            <MarkdownEditor
                              initialContent={cleanData(generatePostData.data)}
                              updateContent={updateGeneratePostData}
                            />
                          </div>

                          {/* Share buttons */}
                          <div className="share-container w-full">
                            <h3 className="mb-2">{t("company_content.share_content")}:</h3>
                            <div className="share-buttons flex space-x-2 mb-4 w-full">
                              <FacebookShareButton url={auth.defaultCompany.website_url}
                                                   quote={cleanData(generatePostData.data)}>
                                <FacebookIcon size={32} round/>
                              </FacebookShareButton>

                              <LinkedinShareButton
                                url={auth.defaultCompany.website_url}
                                description={cleanData(generatePostData.data)}
                                title={modalData?.idea}
                                source={auth.defaultCompany.website_url}
                                // Additional optional parameters
                                summary={modalData?.summary}
                                articleUrl={auth.defaultCompany.website_url}
                              >
                                <LinkedinIcon size={32} round/>
                              </LinkedinShareButton>


                              <TwitterShareButton url={auth.defaultCompany.website_url}
                                                  title={cleanData(generatePostData.data)}>
                                <TwitterIcon size={32} round/>
                              </TwitterShareButton>

                              <WhatsappShareButton url={auth.defaultCompany.website_url}
                                                   title={cleanData(generatePostData.data)}>
                                <WhatsappIcon size={32} round/>
                              </WhatsappShareButton>

                              <TelegramShareButton url={auth.defaultCompany.website_url}
                                                   title={cleanData(generatePostData.data)}>
                                <TelegramIcon size={32} round/>
                              </TelegramShareButton>

                              <EmailShareButton
                                url={auth.defaultCompany.website_url}
                                subject={`${modalData?.emoji} ${modalData?.idea}`}
                                body={`${modalData?.emoji} ${modalData?.idea}
                                       ${generatePostData.data}`}
                                ishtml={true}>
                                <EmailIcon size={32} round/>
                              </EmailShareButton>
                              <div className="flex flex-row m-0">
                                <Tip text={t('copy_to_clipboard')} position="top" showIcon={false}>
                                  <CopyToClipboard
                                    text={cleanData(generatePostData.data)}
                                    onCopy={() => {
                                      setDataCopied(true)
                                      setTimeout(() => {
                                        setDataCopied(false)
                                      }, 10000)
                                    }}
                                  >
                                    <button className="btn-sm bg-gray-900 text-gray-100 hover:bg-gray-800">
                                      <FaRegClipboard size={24}/>
                                    </button>
                                  </CopyToClipboard>
                                  {dataCopied ?
                                    <span className="text-sm text-gray-500 dark:text-gray-400 -ml-3">👍</span> : null}
                                </Tip>
                              </div>
                            </div>
                          </div>

                        </div>
                      ) : (
                        <>
                          <p>
                            {/*{modalData?.summary}*/}
                          </p>
                        </>
                      )}


                      {!generatePostIsLoading && (
                        <div className="space-x-2">
                          {/*  <button*/}
                          {/*    className={"relative p-0.5 inline-flex items-center justify-center font-bold overflow-hidden group rounded-md disabled:opacity-40"}*/}
                          {/*    onClick={(e) => {*/}
                          {/*      e.stopPropagation();*/}
                          {/*      setGeneratePostData(null);*/}
                          {/*      getNewPost(modalData.id);*/}
                          {/*    }}*/}
                          {/*    disabled={generatePostIsLoading}*/}
                          {/*  >*/}
                          {/*<span*/}
                          {/*  className="w-full h-full bg-gradient-to-br from-orange-500 via-pink-500 to-fuchsia-600 group-hover:from-fuchsia-600 group-hover:via-pink-500 group-hover:to-orange-500 absolute"*/}
                          {/*/>*/}
                          {/*    <span*/}
                          {/*      className="relative px-6 py-3 transition-all ease-out bg-gray-900 rounded-md group-hover:bg-opacity-0 duration-400"*/}
                          {/*    >*/}
                          {/*  <span className="relative text-white">{t('company_content.generate_content')}</span>*/}
                          {/*</span>*/}
                          {/*  </button>*/}

                          <button
                            className={"relative p-0.5 inline-flex items-center justify-center font-bold overflow-hidden group rounded-md disabled:opacity-40"}
                            onClick={(e) => {
                              e.stopPropagation();
                              setGeneratePostData(null);
                              getNewPost(modalData.id);
                            }}
                            disabled={generatePostIsLoading}
                          >
                        <span
                          className="w-full h-full bg-gradient-to-br from-orange-500 via-pink-500 to-fuchsia-600 group-hover:from-fuchsia-600 group-hover:via-pink-500 group-hover:to-orange-500 absolute"
                        />
                            <span
                              className="relative px-6 py-3 transition-all ease-out bg-gray-900 rounded-md group-hover:bg-opacity-0 duration-400"
                            >
                          <span className="relative text-white">{t('company_content.schedule_content')}</span>
                        </span>
                          </button>
                        </div>

                      )}

                    </div>
                  </div>
                </div>
                {/* Modal footer */}
                <div className="px-5 py-4">
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button
                      className="btn-sm px-8 border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 text-gray-800 dark:text-gray-300"
                      onClick={(e) => {
                        e.stopPropagation();
                        setBasicModalOpen(false);
                      }}
                    >
                      {t('close')}
                    </button>

                  </div>
                </div>
              </ModalBasic>
            </div>


          </div>
        </div>
      )}


    </div>
  )
    ;
}

export default CompanyContent;