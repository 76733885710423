import React from 'react';
import {register} from "react-social-icons";
import {
  FaBalanceScale,
  FaBook,
  FaBookOpen,
  FaCommentDots,
  FaHandPointer,
  FaLightbulb,
  FaListUl,
  FaMicroscope,
  FaNewspaper,
  FaQuestionCircle,
  FaRegEdit,
  FaSearchPlus,
  FaStar,
  FaUsers
} from "react-icons/fa";

import {motion} from "framer-motion";
import {MdDeleteForever} from "react-icons/md";
import {t} from "i18next";

function ContentTableItem(props) {

  // Register your custom "blog" network
  register("blog", {
    "color": "#00BF00",
    "path": "M0 0h64v64H0zm28.388 32c0 .084-.02.163-.025.247l8.802 4.4a4.3 4.3 0 0 1 2.782-1.037 4.335 4.335 0 0 1 4.335 4.335 4.335 4.335 0 1 1-8.67 0c0-.086.02-.163.025-.247l-8.802-4.4a4.3 4.3 0 0 1-2.782 1.034 4.335 4.335 0 0 1 0-8.67c1.065 0 2.027.402 2.782 1.037l8.802-4.4c-.005-.083-.024-.162-.024-.249a4.333 4.333 0 0 1 4.334-4.332 4.335 4.335 0 0 1 0 8.67 4.28 4.28 0 0 1-2.782-1.04l-8.802 4.403c.005.084.024.163.024.25"
  });

  const totalColor = (status) => {
    switch (status) {
      case 'Paid':
        return 'text-green-500';
      case 'Due':
        return 'text-yellow-500';
      case 'Overdue':
        return 'text-red-500';
      default:
        return 'text-gray-500';
    }
  };

  const statusColor = (status) => {
    switch (status) {
      case 'Paid':
        return 'bg-green-500/20 text-green-700';
      case 'Due':
        return 'bg-yellow-500/20 text-yellow-700';
      case 'Overdue':
        return 'bg-red-500/20 text-red-700';
      default:
        return 'bg-gray-400/20 text-gray-500 dark:text-gray-400';
    }
  };

  const typeIcon = (type) => {
    const iconColor = '#6B7280'
    const iconSize = 16
    switch (type) {
      case 'Dicas Rápidas':
      case 'Quick Tips':
        return (
          <FaLightbulb size={iconSize} color={iconColor}/>
        );
      case 'Guias de Como Fazer':
      case 'How-to Guide':
        return (
          <FaBookOpen size={iconSize} color={iconColor}/>
        );
      case 'Conteúdo de Comparação':
      case 'Comparison Content':
        return (
          <FaBalanceScale size={iconSize} color={iconColor}/>
        );
      case 'Listas':
      case 'Listicle':
        return (
          <FaListUl size={iconSize} color={iconColor}/>
        );
      case 'Conteúdo de Pesquisa':
      case 'Research Content':
        return (
          <FaMicroscope size={iconSize} color={iconColor}/>
        );
      case 'Conteúdo de Referência':
      case 'Reference Content':
        return (
          <FaBook size={iconSize} color={iconColor}/>
        );
      case 'Notícias e Atualizações':
      case 'News & Updates':
        return (
          <FaNewspaper size={iconSize} color={iconColor}/>
        );
      case 'Avaliações':
      case 'Reviews':
        return (
          <FaStar size={iconSize} color={iconColor}/>
        );
      case 'Conteúdo Interativo':
      case 'Interactive Content':
        return (
          <FaHandPointer size={iconSize} color={iconColor}/>
        );
      case 'Case Study':
        return (
          <FaSearchPlus size={iconSize} color={iconColor}/>
        );
      case 'Conteúdo Gerado por Usuários':
      case 'User-Generated Content':
        return (
          <FaUsers size={iconSize} color={iconColor}/>
        );
      case 'Quiz Interativo':
      case 'Interactive Quiz':
        return (
          <FaQuestionCircle size={iconSize} color={iconColor}/>
        );
      case 'Depoimentos de Usuários':
      case 'User Testimonials':
        return (
          <FaCommentDots size={iconSize} color={iconColor}/>
        );
      default:
        return (
          <svg className="fill-current text-gray-400 dark:text-gray-500 shrink-0 mr-2" width="16" height="16"
               viewBox="0 0 16 16">
            <path
              d="M11.4 0L10 1.4l2 2H8.4c-2.8 0-5 2.2-5 5V12l-2-2L0 11.4l3.7 3.7c.2.2.4.3.7.3.3 0 .5-.1.7-.3l3.7-3.7L7.4 10l-2 2V8.4c0-1.7 1.3-3 3-3H12l-2 2 1.4 1.4 3.7-3.7c.4-.4.4-1 0-1.4L11.4 0z"/>
          </svg>
        );
    }
  };

  return (
    <motion.tr
      className="bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700"
      initial={{opacity: 0, y: 20}}
      animate={{opacity: 1, y: 0}}
      exit={{
        opacity: 0,
        x: -300,  // Slide out to the left
        transition: {
          duration: 0.4,
          ease: "easeOut"
        }
      }}
      transition={{duration: 0.3}}
      layout  // Add this to animate remaining items
    >
      {/*<tr>*/}
      <td className="px-2 px-3 py-10  whitespace-nowrap w-px">
        <div className="flex items-center">
          <label className="inline-flex">
            <span className="sr-only">Select</span>
            <input id={props.id} className="form-checkbox" type="checkbox" onChange={props.handleClick}
                   checked={props.isChecked}/>
          </label>
        </div>
      </td>

      {/* min-w-[300px] */}
      {/*<td className="px-2 first:pl-5 last:pr-5 py-3  sm:whitespace-nowrap max-w-sm break-words" >*/}
      <td className="px-0 py-3 min-w-[300px] sm:min-w-[400px] max-w-sm break-words">
        <a href="#"
           onClick={(e) => {
             e.preventDefault();
             e.stopPropagation();
             props.updateModal(props.id);
           }}>
          <div className="flex">
            <span className="text-xl mr-1 -ml-2 sm:-ml-0">{props.emoji}</span>
            <div className="font-bold text-gray-800 dark:text-gray-300 mb-2">
              {props.idea}
            </div>
          </div>
          <div
            className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-gray-400 dark:after:text-gray-600 after:px-2">
                <span className="text text-gray-800 dark:text-gray-300">
                  {props.summary}
                </span>
          </div>
        </a>
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            {props?.socialNetwork && (
              <span className="text-xs text-gray-400 dark:text-gray-500 mr-2">{t("for")} {props?.socialNetwork}</span>
            )}
            <div className="flex items-center">
              {typeIcon(props.type)}
              <span className="text-xs text-gray-800 dark:text-gray-300 ml-1">{props.type}</span>
            </div>
          </div>

          <div className="flex items-center mr-4 sm:mr-10">
            <button
              className="text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400 rounded-full mr-2"
              onClick={(e) => {
                e.stopPropagation();
                props.updateModal(props.id);
              }}
            >
              <span className="sr-only">Edit</span>
              {/*<svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">*/}
              {/*  <path*/}
              {/*    d="M19.7 8.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM12.6 22H10v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L17.4 12l1.6-1.6 2.6 2.6-1.6 1.6z"/>*/}
              {/*</svg>*/}
              <FaRegEdit className="mr-3" size={24}/>
            </button>

            <button
              className="text-red-500 hover:text-red-600 rounded-full"
              onClick={props.onRemove}
            >
              <span className="sr-only">Delete</span>
              {/*<svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">*/}
              {/*  <path d="M13 15h2v6h-2zM17 15h2v6h-2z"/>*/}
              {/*  <path*/}
              {/*    d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z"/>*/}
              {/*</svg>*/}
              <MdDeleteForever className={'text-red-500'} size={24}/>
            </button>
          </div>
        </div>


      </td>


      {/*</tr>*/}
    </motion.tr>

  );
}

export default ContentTableItem;
