import React from 'react';
import {FaPen, FaSave} from "react-icons/fa";

const LinkButton = ({href = "#_", onClick, children, icon = null}) => {

  const getIcon = (icon) => {
    switch (icon) {
      case 'edit':
        return <FaPen size={12} color=""/>
      case 'save':
        return <FaSave size={14} color=""/>
      default:
        return <FaPen size={12} color=""/>
    }
  }

  return (
    <a
      href={href}
      onClick={onClick}
      className="relative inline-flex items-center justify-start py-1 pl-4 pr-8 overflow-hidden font-semibold text-indigo-700 transition-all duration-150 ease-in-out rounded hover:pl-6 hover:pr-6 group"
    >
      <span
        className="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-700 group-hover:h-full"></span>
      <span className="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
        {getIcon(icon)}
      </span>
      <span
        className="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200 text-gray-50">
        {getIcon(icon)}
      </span>
      <span className="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">
        {children}
      </span>
    </a>
  );
};

export default LinkButton;
