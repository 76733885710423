import React, {useEffect, useState} from "react";
import {useAuth} from "../../util/auth";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import {useTranslation} from "react-i18next";

import ProfileHeader from "./ProfileHeader";
import CompanyOverview from "./CompanyOverview";

import CompanySelect from "../CompanySelect";

import {AnimatePresence, motion} from 'framer-motion';
import EmptyState from "../../pages/utility/EmptyState";
import StrategySection from "./StrategySection";
import CompanyRoadmap from "./CompanyRoadmap";
import CompanyReport from "./CompanyReport";
import LoadingAnimation from "../LoadingAnimation";
import CompanyContent from "../Content/CompanyContent";
import LanguageSelectorBig from "../LanguageSelectorBig";
import {FaRoad, FaUserAlt} from "react-icons/fa";
import {MdDescription} from "react-icons/md";
import {PiArticleNyTimesBold} from "react-icons/pi";
import {BsCalendar2Week} from "react-icons/bs";
import {IoAnalyticsOutline} from "react-icons/io5";

const variants = {
  initial: {opacity: 0, scale: 0.8},
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: [0.175, 0.85, 0.42, 0.96], // Easing function for a bounce effect
    },
  },
  exit: {opacity: 0, scale: 0.8, transition: {duration: 0.3}},
};


function DashboardSection(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  const auth = useAuth();

  const [loading, setLoading] = useState(true);

  const [activeTab, setActiveTab] = useState('Profile');
  const [selectedItems, setSelectedItems] = useState([]);
  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };
  const [animationKey, setAnimationKey] = useState(0);
  const [notificationSuccessOpen, setNotificationSuccessOpen] = useState(true);

  useEffect(() => {
    if (!auth?.companies?.length) {
      auth.updateCompanies().then(() => {
        setLoading(false);
      });
    }
  }, []);

  // Update the animation key whenever defaultCompany changes
  useEffect(() => {
    if (auth?.defaultCompany) {
      setLoading(false);
      setAnimationKey(prevKey => prevKey + 1);
    }

  }, [auth.defaultCompany]);

  if (loading === true) {
    return (
      <LoadingAnimation/>
    )
  }

  // =======
  // ======= No company setup ====== //
  // if (true) {
  if (auth?.companies?.length === 0 || !auth?.defaultCompany) {
    return (
      <Section
        size={props.size}
        bgColor={props.bgColor}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        textColor={props.textColor}
        className={"mt-0 pt-0"}
      >
        <div className="container">
          <SectionHeader
            title={t("Dashboard_page_title")}
            subtitle={false}
            strapline=""
            className="text-center mb-4"
          />

          <LanguageSelectorBig/>

          <EmptyState
            title={false}
            subtitle={t("dashboard.setup_company")}
            description={t("dashboard.no_company_setup")}
            button={t("dashboard.button_setup_new_company")}
            buttonLink={"/onboarding"}
            noicon={true}
          />
        </div>
      </Section>
    );
  }

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      className={"mt-0 pt-0 mx-0 px-0"}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center mb-4 mx-0 px-0"
        />

        {/*<Notification type="success"*/}
        {/*              open={notificationSuccessOpen}*/}
        {/*              setOpen={setNotificationSuccessOpen}*/}
        {/*              className="w-full"*/}
        {/*>*/}
        {/*  <div className="font-medium text-gray-800 dark:text-gray-100 mb-1">*/}
        {/*    {t("welcome")}*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    {t("Dashboard_welcome_message")}*/}
        {/*  </div>*/}
        {/*</Notification>*/}

        {/*<Notification type="success"*/}
        {/*              open={notificationSuccessOpen}*/}
        {/*              setOpen={setNotificationSuccessOpen}*/}
        {/*              className="w-full"*/}
        {/*>*/}
        {/*  <div className="font-medium text-gray-800 dark:text-gray-100 mb-1">*/}
        {/*    {t("welcome")}*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    {t("Dashboard_welcome_message")}*/}
        {/*  </div>*/}
        {/*</Notification>*/}


        {/* ======= COMPANY SELECTOR =======   */}
        <div className="grid grid-flow-col auto-cols-max justify-start justify-end gap-2 mb-1">
          <CompanySelect/>
        </div>


        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={animationKey} // Use a unique key to trigger re-render
            initial="initial"
            animate="animate"
            exit="exit"
            variants={variants}
            transition={{duration: 0.5}}
          >
            <ProfileHeader UserAvatar={auth?.company.logo_url}
                           CompanyName={auth?.company.name}
                           CompanyBio={auth?.company.description}
                           CompanyWebsite={auth?.company.website_url}
                           CompanyLocation={auth?.company.country}
                           localCompany={auth?.company}
            />
            <div className="relative w-full mt-3 px-0 pb-0 ">

              {/* ======= TABS SECTION =======   */}
              <div className="relative mb-6">
                <div className="absolute bottom-0 w-full h-px bg-gray-200 dark:bg-gray-700/60" aria-hidden="true"></div>
                <ul
                  className="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">

                  {/* Company profile */}
                  <li
                    className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
                    <a
                      className={`block pb-3 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 whitespace-nowrap ${activeTab === 'Profile' && 'border-b-2 border-violet-500 !text-violet-500'}`}
                      href="#0"
                      onClick={() => setActiveTab('Profile')}>
                      <div className="flex items-center">
                        <FaUserAlt size={14} className="mr-1"/>
                        {t("dashboard.tab.profile")}
                      </div>
                    </a>
                  </li>

                  {/* ROADMAP */}
                  <li
                    className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
                    <a
                      className={`block pb-3 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 whitespace-nowrap ${activeTab === 'Roadmap' && 'border-b-2 border-violet-500 !text-violet-500'}`}
                      href="#0"
                      onClick={() => setActiveTab('Roadmap')}
                    >
                      <div className="flex items-center">
                        <FaRoad size={14} className="mr-1"/>
                      {t("dashboard.tab.roadmap")}
                      </div>
                    </a>
                  </li>

                  {/* REPORT */}
                  <li
                    className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
                    <a
                      className={`block pb-3 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 whitespace-nowrap ${activeTab === 'Report' && 'border-b-2 border-violet-500 !text-violet-500'}`}
                      href="#0"
                      onClick={() => setActiveTab('Report')}
                    >
                      <div className="flex items-center">
                        <MdDescription size={14} className="mr-1"/>
                        {t("dashboard.tab.report")}
                      </div>
                    </a>
                  </li>

                  {/*/!* Marketing Strategy *!/*/}
                  {/*<li*/}
                  {/*  className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">*/}
                  {/*  <a*/}
                  {/*    className={`block pb-3 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 whitespace-nowrap ${activeTab === 'Strategy' && 'border-b-2 border-violet-500 !text-violet-500'}`}*/}
                  {/*    href="#0"*/}
                  {/*    onClick={() => setActiveTab('Strategy')}*/}
                  {/*  >*/}
                  {/*    {t("dashboard.tab.marketing_strategy")}*/}
                  {/*  </a>*/}
                  {/*</li>*/}

                  {/* Content */}
                  <li
                    className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
                    <a
                      className={`block pb-3 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 whitespace-nowrap ${activeTab === 'Content' && 'border-b-2 border-violet-500 !text-violet-500'}`}
                      href="#0"
                      onClick={() => setActiveTab('Content')}
                    >
                      <div className="flex items-center">
                        <PiArticleNyTimesBold size={14} className="mr-1"/>
                        {t("dashboard.tab.content")}
                      </div>
                    </a>
                  </li>

                  {/* Schedule */}
                  <li
                    className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
                    <a
                      className={`block pb-3 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 whitespace-nowrap ${activeTab === 'Schedule' && 'border-b-2 border-violet-500 !text-violet-500'}`}
                      href="#0"
                      onClick={() => setActiveTab('Schedule')}
                    >
                      <div className="flex items-center">
                        <BsCalendar2Week size={14} className="mr-1"/>
                        {t("dashboard.schedule.title")}
                      </div>
                    </a>
                  </li>

                  {/* Statistics */}
                  <li
                    className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
                    <a
                      className={`block pb-3 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 whitespace-nowrap ${activeTab === 'Statistics' && 'border-b-2 border-violet-500 !text-violet-500'}`}
                      href="#0"
                      onClick={() => setActiveTab('Statistics')}
                    >
                      <div className="flex items-center">
                        <IoAnalyticsOutline size={14} className="mr-1"/>
                        {t("dashboard.tab.statistics")}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              {/* ======= PROFILE ======= */}
              {activeTab === 'Profile' && (
                <>
                  <CompanyOverview
                    CompanyName={auth?.company.name}
                    CompanyBio={auth?.company.description}
                    CompanyWebsite={auth?.company.website_url}
                  />
                </>
              )}

              {/* ======= ROADMAP ======= */}
              {activeTab === 'Roadmap' && (
                <>
                  <CompanyRoadmap
                    company_website={auth?.company.website_url}
                  />
                </>
              )}

              {/* ======= REPORT ======= */}
              {activeTab === 'Report' && (

                <CompanyReport
                  CompanyName={auth?.company.name}
                  CompanyBio={auth?.company.description}
                  CompanyWebsite={auth?.company.website_url}
                />

              )}

              {/* ======= SCHEDULE ======= */}
              {activeTab === 'Schedule' && (
                <>
                  <EmptyState
                    title={false}
                    subtitle={t("dashboard.schedule.title")}
                    description={t("dashboard.schedule.description")}
                    button={false}
                  />
                </>
              )}

              {/* ======= STATISTICS ======= */}
              {activeTab === 'Statistics' && (
                <>
                  <EmptyState
                    title={false}
                    subtitle={t("dashboard.statistics_subtitle")}
                    description={t("dashboard.statistics_description")}
                    button={false}
                  />
                </>
              )}


              {activeTab === 'Content' && (
                <CompanyContent/>
              )}

              {activeTab === 'Strategy' && (
                <>
                  <StrategySection
                  />
                </>
              )}

            </div>


            {/*<Divider>Total posts from your connected accounts</Divider>*/}
          </motion.div>
        </AnimatePresence>

      </div>
    </Section>
  );
}

export default DashboardSection;
