import React, {useEffect} from 'react';
import {useAuth} from "../../util/auth";
import {useTranslation} from "react-i18next";
import SocialNetworks from "./SocialNetworks";
import {
  FaBalanceScale,
  FaChartLine,
  FaChartPie,
  FaExclamationTriangle,
  FaInfoCircle,
  FaLightbulb,
  FaNewspaper,
  FaThumbsDown,
  FaThumbsUp
} from "react-icons/fa";
import log from "../../util/logger";
import {FaPersonRunning} from "react-icons/fa6";
import {useAIInitialReportData} from "../../util/hooks";
import {getFullLanguageName, getLocalStorageData, removeImageTagFromMarkdown} from "../../util/util";
import LoadingAnimation from "../LoadingAnimation";

function CompanyOverview(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  const auth = useAuth();

  const localWebsiteData = getLocalStorageData(`websiteData-${auth.defaultCompany.website_url}`);

  const currentLanguage = getFullLanguageName(i18n.language)

  // const {data, isLoading, error} = useAIInitialReportData(
  //   {
  //     ...auth.defaultCompany,
  //     websiteCheckResponse: getLocalStorageData(`websiteData-${auth.defaultCompany.website_url}`) || {},
  //   },
  //   currentLanguage,
  //   (resp) => { /* onSuccess callback */
  //     log.debug("useAIInitialReportData Generated data", resp);
  //
  //   },
  //   (err) => { /* onError callback */
  //     log.error("useAIInitialReportData Error generating data", err);
  //   }
  // );

  const [enableAIData, setEnableAIData] = React.useState(false);


  const [AI, setAI] = React.useState({
    "market_analysis": "",
    "market_insights": "",
    "market_swot_analysis": {
      "strengths": "",
      "weaknesses": "",
      "opportunities": "",
      "threats": ""
    },
    "market_news": [
      {
        "title": "",
        "url": "",
        "summary": "",
        "published_at": ""
      },
      {
        "title": "",
        "url": "",
        "summary": "",
        "published_at": ""
      }
    ],
    "main_competitors": ""
  })

  // log.debug(`Local generatedData-${props.CompanyWebsite}`);
  // const local_storage = getLocalStorageData(`generatedData-${props.CompanyWebsite}`);

  // if (local_storage) {
  //   setAI(local_storage)
  //   log.debug("Local storage data found", AI);
  // }


  // ======= AI COMPANY OVERVIEW START =======
  // const {
  //   data: AI_data,
  //   isLoading: AI_isLoading,
  //   error: AI_Error,
  //   isError: AI_isError,
  //   isSuccess: AI_IsSuccess,
  //   refetch: AI_Refetch,
  // } = useQuery(
  //   ['AI_CompanyOverview', props.CompanyWebsite],
  //   () => api.aiCompanyOverview(props.CompanyWebsite),
  //   {
  //     staleTime: Infinity, // Data never goes stale
  //     cacheTime: Infinity, // Data stays in cache forever
  //     refetchOnWindowFocus: false, // Disable refetch on window focus
  //     enabled: enableAIData, // Enable the query based on condition
  //     // initialData: (() => {
  //     //   const localData = getLocalStorageData(localStorageKeyWebsiteCheck);
  //     //   const ret = localData !== null && localData !== undefined && localData !== '' ? localData : undefined;
  //     //   log.debug("Initial data for website check:", ret);
  //     //   return ret
  //     // })(),
  //     onSuccess: (data) => {
  //       log.info("AI Company Overview data fetched successfully", data);
  //       log.debug("data type", typeof data);
  //       log.debug("market_analysis", data.market_analysis);
  //       // AI = data;
  //       setAI(data);
  //       // setLocalStorageData(localStorageKeyWebsiteCheck, data); // Store data in localStorage on successful fetch
  //     },
  //     onError: (error) => {
  //       log.error("Error fetching AI Company Overview data", error);
  //       log.info("website_url", props.CompanyWebsite);
  //
  //       const localData = getLocalStorageData(`AI_InitialReport-${auth.defaultCompany.website_url}`);
  //       log.warn(">>>Local Storage data", localData);
  //       const ret = localData !== null && localData !== undefined && localData !== '' ? localData : undefined;
  //       log.debug("AI_InitialReport > initialData:  localData", ret);
  //       log.debug("AI_InitialReport > initialData:  localData", `AI_InitialReport-${auth.defaultCompany.website_url}`);
  //       // AI = ret;
  //       setAI(localData.data);
  //
  //     },
  //   }
  // );
// ======= AI COMPANY OVERVIEW  END =======

  // ############## Generate AI Initial Report data ##############
  const company_details = getLocalStorageData(`websiteData-${auth.defaultCompany.website_url}`);
  const {
    data: AI_data,
    isLoading: AI_isLoading,
    error: AI_isError
  } = useAIInitialReportData(
    {
      ...auth.defaultCompany,
      websiteCheckResponse: localWebsiteData?.original_response ? removeImageTagFromMarkdown(localWebsiteData.original_response.data.markdown) : '',
      location: `${auth.defaultCompany.address} - 
      ${auth.defaultCompany.city}, ${auth.defaultCompany.state},
       ${auth.defaultCompany.postal_code}, ${auth.defaultCompany.country}`,
      Website_Details: company_details?.original_response?.data?.markdown ? removeImageTagFromMarkdown(company_details?.original_response?.data?.markdown) : ''
    },
    getFullLanguageName(i18n.language),
    (resp) => { /* onSuccess callback */
      log.debug("useAIInitialReportData CompanyOverview Generated data", resp);
    },
    (err) => { /* onError callback */
      log.error("useAIInitialReportData CompanyOverview Error generating data", err);
    }
  );

  useEffect(() => {
    log.debug(" CompanyOverview AI data fetched successfully", AI_data);
    // AI_Refetch()
  }, [AI_data]);

  useEffect(() => {
    setEnableAIData(true);
  }, []);

  return (
    <div
      className="grow flex flex-col md:translate-x-0 transition-transform duration-300 ease-in-out translate-x-0"
    >
      <div className="relative px-0 sm:px-6 pb-4">
        <div className="flex flex-col xl:flex-row xl:space-x-16">
          {/* Main content */}
          <div className="flex-1 space-y-5 mb-8 xl:mb-0">

            {/* ABOUT */}
            <div className="border-b-2 border-white dark:border-gray-800 box-content p-2">
              <h2 className="text-gray-800 dark:text-gray-100 font-semibold mb-2">
                <div className="flex items-center">
                  <FaInfoCircle className="mr-2"/>
                  {t("dashboard.about")}
                </div>
              </h2>
              <div className="text-sm space-y-2">
                {AI_isLoading ? (
                  <LoadingAnimation/>
                ) : (
                  <p>
                    {props.CompanyBio || "The goal of this section is to present a concise yet informative overview of the company"}
                  </p>
                )}
              </div>
            </div>

            {/* MARKET analysis */}

            {AI_isLoading || AI_isError ? (
              <LoadingAnimation text={"✨" + t("dashboard.loading_ai_content")}/>
            ) : (
              <>
                <div className="border-b-2  border-white dark:border-gray-800 box-content p-2">
                  <h2 className="text-gray-800 dark:text-gray-100 font-semibold mb-2">
                    <div className="flex items-center">
                      <FaChartPie className="mr-2"/>
                      {t("dashboard.market_analysis")}
                      {/* TOOLTIP */}
                      <div className="relative group">
                        <span className="ml-2 text-sm cursor-pointer">✨</span>
                        <div
                          className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-1 hidden group-hover:block w-max p-2 text-xs text-white bg-gray-800 rounded-md shadow-lg dark:bg-gray-700">
                          {t("dashboard.tip_information_from_ai")}
                        </div>
                      </div>
                    </div>
                  </h2>
                  <div>
                    <div className="text-sm space-y-2">
                      <p>{AI_data?.data?.market_analysis}</p>
                    </div>
                  </div>
                </div>

                {/* MARKET SWOT analysis */}
                <div className="border-b-2  border-white dark:border-gray-800 box-content p-2">
                  <h2 className="text-gray-800 dark:text-gray-100 font-semibold mb-2">
                    <div className="flex items-center">
                      <FaBalanceScale className="mr-2"/>
                      {t("dashboard.market_swot_analysis")}
                      {/* TOOLTIP */}
                      <div className="relative group">
                        <span className="ml-2 text-sm cursor-pointer">✨</span>
                        <div
                          className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-1 hidden group-hover:block w-max p-2 text-xs text-white bg-gray-800 rounded-md shadow-lg dark:bg-gray-700">
                          {t("dashboard.tip_information_from_ai")}
                        </div>
                      </div>
                    </div>
                  </h2>
                  <div>
                    <div className="space-y-4">
                      <div className="space-y-2">
                        <div className="text-sm space-y-2">
                          {['strengths', 'weaknesses', 'opportunities', 'threats'].map((key) => (
                            AI_data?.data?.market_swot_analysis[key] && (
                              <div
                                key={key}
                                className={`flex items-center p-2 rounded-md border ${
                                  key === "strengths"
                                    ? "border-green-200 bg-green-50 dark:bg-green-900 dark:border-green-600"
                                    : key === "weaknesses"
                                      ? "border-red-200 bg-red-50 dark:bg-red-900 dark:border-red-600"
                                      : key === "opportunities"
                                        ? "border-blue-200 bg-blue-50 dark:bg-blue-900 dark:border-blue-600"
                                        : "border-yellow-200 bg-yellow-50 dark:bg-yellow-900 dark:border-yellow-600"
                                }`}
                              >
                                <div className="flex-shrink-0 mr-2">
                                  {key === "strengths" &&
                                    <FaThumbsUp className="text-green-600 dark:text-green-400" size={16}/>}
                                  {key === "weaknesses" &&
                                    <FaThumbsDown className="text-red-600 dark:text-red-400" size={16}/>}
                                  {key === "opportunities" &&
                                    <FaChartLine className="text-blue-600 dark:text-blue-400" size={16}/>}
                                  {key === "threats" &&
                                    <FaExclamationTriangle className="text-yellow-600 dark:text-yellow-400" size={16}/>}
                                </div>
                                <div>
                                  <strong
                                    className="text-gray-800 dark:text-gray-100">{t(`dashboard.${key}`)}: </strong>
                                  <span
                                    className="text-gray-700 dark:text-gray-200">{AI_data?.data?.market_swot_analysis[key]}</span>
                                </div>
                              </div>
                            )
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* MARKET INSIGHTS */}
                <div className="border-b-2  border-white dark:border-gray-800 box-content p-2">
                  <h2 className="text-gray-800 dark:text-gray-100 font-semibold mb-2">
                    <div className="flex items-center">
                      <FaLightbulb className="mr-2"/>
                      {t("dashboard.market_insights")}
                      {/* TOOLTIP */}
                      <div className="relative group">
                        <span className="ml-2 text-sm cursor-pointer">✨</span>
                        <div
                          className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-1 hidden group-hover:block w-max p-2 text-xs text-white bg-gray-800 rounded-md shadow-lg dark:bg-gray-700">
                          {t("dashboard.tip_information_from_ai")}
                        </div>
                      </div>
                    </div>
                  </h2>
                  <div>
                    <div className="text-sm space-y-2">
                      <p>
                        {AI_data?.data?.market_insights}
                      </p>
                    </div>
                  </div>
                </div>

                {/* MAIN CONPETITORS */}
                <div className="border-b-2  border-white dark:border-gray-800 box-content p-2">
                  <h2 className="text-gray-800 dark:text-gray-100 font-semibold mb-2">
                    <div className="flex items-center">
                      <FaPersonRunning className="mr-2"/>
                      {t("dashboard.competitors")}
                      {/* TOOLTIP */}
                      <div className="relative group">
                        <span className="ml-2 text-sm cursor-pointer">✨</span>
                        <div
                          className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-1 hidden group-hover:block w-max p-2 text-xs text-white bg-gray-800 rounded-md shadow-lg dark:bg-gray-700">
                          {t("dashboard.tip_information_from_ai")}
                        </div>
                      </div>
                    </div>
                  </h2>
                  <div>
                    <div className="text-sm space-y-2">
                      <p>
                        {AI_data?.data?.main_competitors}
                      </p>
                    </div>
                  </div>
                </div>

                {/* NEWS ABOUT MARKET */}
                <div className="border-b-2  border-white dark:border-gray-800 box-content p-2">
                  <h2 className="text-gray-800 dark:text-gray-100 font-semibold mb-2">
                    <div className="flex items-center">
                      <FaNewspaper className="mr-2"/>
                      {t("dashboard.market_news")}
                    </div>
                  </h2>
                  <div>
                    <div className="text-sm space-y-6">
                      {AI_data?.data?.market_news.map((news, index) => (
                        <div
                          className="border-b pb-4 mb-4 border-gray-200 dark:border-gray-700 last:border-none"
                          key={index}
                        >
                          <div className="flex items-center justify-between">
                            <h3 className="font-bold text text-gray-800 dark:text-gray-100">
                              {news.title}
                            </h3>
                            <span className="text-xs text-gray-500 dark:text-gray-400">
          {news.published_at ? new Date(news.published_at).toLocaleDateString() : "Today"}
        </span>
                          </div>
                          <div className="flex justify-between items-center mt-2">
                            <p className="text-gray-700 dark:text-gray-300 flex-1">
                              {news.summary ? news.summary : "No summary available"}
                            </p>
                            <a
                              href={news.url}
                              target="_blank"
                              rel="noreferrer"
                              className="ml-4 text-blue-500 hover:underline dark:text-blue-400"
                            >
                              Ver mais
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>


                  </div>
                </div>
              </>
            )}

            {/* SOCIAL NETWORKS */}
            <div className="border-b-2  border-white dark:border-gray-800 box-content p-1">
              <SocialNetworks/>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyOverview;
