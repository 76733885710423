import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";

import log from "../util/logger"
import LinkButton from "./buttons/LinkButton";

const MarkdownEditor = ({initialContent = "", updateContent}) => {
  const {t, i18n} = useTranslation();
  const [content, setContent] = useState(initialContent);
  const [savedContent, setSavedContent] = useState("");
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [mode, setMode] = useState("preview"); // "edit" or "preview"
  const mdParser = new MarkdownIt();

  // Update state when initialContent changes (e.g., dynamically from props)
  useEffect(() => {
    setContent(initialContent);
  }, [initialContent]);

  const handleSave = () => {
    setSavedContent(content);
    log.debug("Saved content:", content);
    updateContent(content);
    setEnableSaveButton(false);
  };

  const handleEditorChange = ({text}) => {
    setContent(text);
    setEnableSaveButton(text !== initialContent);
  };

  return (
    <>
      <style>
        {`
                    .dark .rc-md-editor {
                        border-color: rgb(55 65 81) !important;
                        background-color: rgb(17 24 39) !important;
                    }
                    
                    .dark .rc-md-editor .editor-container .sec-md .input {
                        background-color: rgb(17 24 39) !important;
                        color: white !important;
                    }
                    
                    .dark .rc-md-editor .toolbar-tab {
                        background-color: rgb(31 41 55) !important;
                        border-bottom-color: rgb(55 65 81) !important;
                    }
                    
                    .dark .rc-md-editor .toolbar-tab .button {
                        color: rgb(209 213 219) !important;
                    }
                    
                    .dark .rc-md-editor .toolbar-tab .button:hover {
                        color: white !important;
                        background-color: rgb(55 65 81) !important;
                    }
                `}
      </style>
      <div className="w-full h-full mx-auto">
        <div className="border h-full border-gray-300 dark:border-gray-700 rounded p-0">
          {mode === "edit" ? (
            <MdEditor
              value={content}
              style={{height: "500px"}}
              renderHTML={(text) => mdParser.render(text)}
              onChange={handleEditorChange}
              onSave={handleSave}
              config={{
                view: {
                  menu: true,
                  md: true,
                  html: false,
                },
              }}
              plugins={[
                "header",
                "font-bold",
                "font-italic",
                "list-unordered",
                "list-ordered",
                "clear",
                "logger",
                "save",
                "full-screen",
                // Remove or add plugins to customize the toolbar
                // Available plugins:
                // 'font-bold'
                // 'font-italic'
                // 'font-underline'
                // 'font-strikethrough'
                // 'list-unordered'
                // 'list-ordered'
                // 'block-quote'
                // 'block-wrap'
                // 'block-code-inline'
                // 'block-code-block'
                // 'table'
                // 'image'
                // 'link'
                // 'mode-toggle'
              ]}
            />
          ) : (
            // <div
            //   className="p-4 bg-gray-100 dark:bg-gray-900 rounded min-h-[500px] overflow-auto"
            //   dangerouslySetInnerHTML={{__html: mdParser.render(content)}}
            // />
            <div
              className="prose text-base font-medium prose-indigo dark:prose-invert rounded-b-md bg-gray-100 dark:bg-gray-900 p-1 w-full max-w-full h-full ">
              <div
                className="p-4 bg-gray-100 dark:bg-gray-900 rounded min-h-[500px] overflow-auto"
                dangerouslySetInnerHTML={{__html: mdParser.render(content)}}
              />

              {/*<MarkdownRenderer content={content}/>*/}
            </div>
          )}

          <div className="flex justify-between mb-1 ml-1">
            {/*<button*/}
            {/*  className="btn-xs bg-gray-900 text-gray-100 hover:bg-gray-800 dark:bg-gray-200 dark:text-gray-800 dark:hover:bg-white disabled:opacity-50 disabled:cursor-not-allowed shadow-[0px_0px_6px_1px_rgba(12,_239,_47,_0.72)]"*/}
            {/*  onClick={() => setMode(mode === "edit" ? "preview" : "edit")}*/}
            {/*>*/}
            {/*  {mode === "edit" ? t("Preview") : t("Edit")}*/}
            {/*</button>*/}
            <LinkButton onClick={() => setMode(mode === "edit" ? "preview" : "edit")} icon='edit'>
              {mode === "edit" ? t("preview") : t("edit")}
            </LinkButton>
            {enableSaveButton && (
              // <button
              //   onClick={(e) => {
              //     e.stopPropagation();
              //     handleSave();
              //   }}
              //   className="btn-xs bg-gray-900 text-gray-100 hover:bg-gray-800 dark:bg-gray-200 dark:text-gray-800 dark:hover:bg-white disabled:opacity-50 disabled:cursor-not-allowed shadow-[0px_0px_6px_1px_rgba(12,_239,_47,_0.72)]"
              //   disabled={!enableSaveButton}
              // >
              //   {t("Save")}
              // </button>
              <LinkButton onClick={(e) => {
                e.stopPropagation();
                handleSave();
              }}
                          disabled={!enableSaveButton}
                          icon='save'>
                {t("save")}
              </LinkButton>
            )}
          </div>

        </div>
      </div>
    </>
  );
};

export default MarkdownEditor;
