import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useAuth} from "../../util/auth";
import {useFormContext} from '../../util/FormContext';
import {useTranslation} from "react-i18next";
import WebsiteChecking from "./WebsiteChecking";
import capitalize from 'lodash/capitalize';
import Banner2 from "../Banner2";
import api from "../../api";
import log from "../../util/logger";
import CompanyTileCard from "./CompanyTileCard";
import {useQuery} from "@tanstack/react-query";
import {
  checkIsValidURL,
  extractDomain,
  getFullLanguageName,
  getLocalStorageData,
  isValidEmail,
  setLocalStorageData,
  urlClean
} from "../../util/util";
import {GenerateRequestModel} from "../../util/hooks";

const Step2Form = forwardRef((props, ref) => {
  log.debug("------- Step2Form.js - Step2Form called -------");
  const auth = useAuth();
  // Translation
  const {t, i18n} = useTranslation();

  const {
    formData,
    updateFormData,
    resetFormData,
    isWebsiteChecked,
    setIsWebsiteChecked
  } = useFormContext();

  // const [loading, setLoading] = useState(true); // Track loading state
  const [banner2ErrorOpen, setBanner2ErrorOpen] = useState(false);
  const [banner2SuccessOpen, setBanner2SuccessOpen] = useState(false);
  const textareaRef = useRef(null);
  const inputRef = useRef(null);
  const [shoudGenerateData, setShouldGenerateData] = useState(false);
  const [varResponse, setVarResponse] = useState({});
  const [enableWebsiteCheck, setEnableWebsiteCheck] = useState(false);
  const [websiteDataIsValid, setWebsiteDataIsValid] = useState(false);
  const website_to_check = urlClean(formData.website)
  // const localStorageKeyWebsiteCheck = `websiteData-${formData.website}`

  const currentLanguage = useRef(getFullLanguageName(i18n.language));

  const localGeneratedKey = `generatedData-${formData.website}`

  const [flash, setFlash] = useState(false);

  useEffect(() => {
    if (localGeneratedKey) {
      auth?.updateLocalGeneratedKey(localGeneratedKey);
    }
  }, [localGeneratedKey, auth]);

  // Adjust the textarea height whenever the text changes
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [formData.companyHeadline]);


// ======= WEBSITE CHECKING START =======
  const {
    data: checkWebsiteData,
    isLoading: checkWebsiteIsLoading,
    error: checkWebsiteError,
    isError: checkWebsiteisError,
    isSuccess: checkWebsiteisSuccess,
    refetch: checkWebsiteRefetch,
  } = useQuery(
    ['websiteCheck', website_to_check],
    () => api.getWebsiteData(website_to_check),
    {
      staleTime: Infinity, // Data never goes stale
      cacheTime: Infinity, // Data stays in cache forever
      refetchOnWindowFocus: false, // Disable refetch on window focus
      enabled: enableWebsiteCheck, // Enable the query based on condition

      // Conditionally set initialData if there is data in localStorage
      initialData: (() => {
        const localData = getLocalStorageData(`websiteData-${formData.website}`);
        const ret = localData !== null && localData !== undefined && localData !== '' ? localData : undefined;
        log.debug("Initial data for website check:", ret);
        return ret
      })(),
      onSuccess: (data) => {
        log.debug("Website data saved in the backend, saved in localStorage");
        setLocalStorageData(`websiteData-${formData.website}`, data); // Store data in localStorage on successful fetch
      },
      onError: (error) => {
      },
    }
  );
// ======= WEBSITE CHECKING END =======


  useEffect(() => {
    window.scrollTo(0, 0);
    log.debug("formData in Step2Form:", formData);
    if (formData.website) {
      const website_to_check = urlClean(formData.website);
      if (checkIsValidURL(website_to_check)) {
        log.debug("Website valid, checking:", website_to_check);
        setEnableWebsiteCheck(true);
      } else {
        log.error("Invalid website:", formData.website);
        setBanner2ErrorOpen(true);
        setBanner2SuccessOpen(false);
      }
    } else {
      log.warn("Website is empty, skipping website check.");
    }
  }, []);

  const handleWebsiteCheck = () => {
    const websiteDataisValid = checkWebsiteData && checkWebsiteData.title && checkWebsiteData.title.length > 0 && checkWebsiteData.title !== "Privacy error";
    if (!websiteDataisValid) {
      log.warn("Website data downloaded but is invalid,  skipping data load.");
    }

    if (checkWebsiteisError || (checkWebsiteData === undefined) || (checkWebsiteData === null) || !websiteDataisValid) {
      log.error("Website handle data failed, details:", checkWebsiteError);
      const option = formData.option
      resetFormData()
      updateFormData({website: website_to_check, option: option});
      setBanner2ErrorOpen(true);
      setBanner2SuccessOpen(false);
    } else {
      log.debug("Website check success, details checkWebsiteData:", checkWebsiteData);
      setWebsiteDataIsValid(true);
      loadDatafromAPI()
    }

  }

  useEffect(() => {
    if (checkWebsiteisError) {
      log.error("Website check fail, details:", checkWebsiteError);
      handleWebsiteCheck()
      return;
    }

    if (checkWebsiteisSuccess) {
      log.debug("Website check success, details checkWebsiteData:", checkWebsiteData);
      handleWebsiteCheck()
    }
  }, [checkWebsiteisError, checkWebsiteisSuccess, checkWebsiteData]);

  const GetAddressByAI = (data) => {
    log.debug("GetAddressByAI called");
    const dataStr = JSON.stringify(data)
    const prompt = `## The values of this should be written in: ${currentLanguage.current}  \n\n## DATA: ${dataStr}`
    const requestModel = new GenerateRequestModel(prompt, 2000, 'openai', formData.website, 'ScrapeAddress');
    api.fetchGeneratedData2(requestModel).then(
      (resp) => {
        log.info("GetAddressByAI resp:", resp)

        const address = resp.data?.address || '';
        const city = resp.data?.city || '';
        const state = resp.data?.state || '';
        const postal_code = resp.data?.postal_code || '';
        const country = resp.data?.country || 'United States';
        const contact_phone = resp.data?.contact_phone;
        const companyEmail = resp.data?.emails?.[0] || '';
        const companyHeadline = resp.data?.description || '';
        const companyName = resp.data?.business_name || '';
        const industry = resp.data?.industry || '';

        updateFormData({
          companyEmail: companyEmail,
          phoneNumbers: contact_phone,

          companyName: companyName,
          companyHeadline: companyHeadline,
          industry: industry,

          street: address,
          city: city,
          state: state,
          postalCode: postal_code,
          country: country,
        })
      }
    ).catch((error) => {
      log.error("GetAddressByAI error:", error)
    })
  }


  const loadDatafromAPI = () => {
    const response = checkWebsiteData;
    const emails = response.emails?.map(email => email.value).filter(isValidEmail)[0] || '';

    // Always get information using AI
    GetAddressByAI(response.original_response)

    log.debug("response: ", response);
    log.debug("response.original_response: ", response.original_response);

    // I need to create more fields for phone numbers in the backend
    // const phoneNumbers = response.phone_numbers?.join(', ') || '';
    const phoneNumbers = response.phone_numbers?.[0] ? response.phone_numbers[0].slice(0, 19) : '';
    const companyName = formData.website ? capitalize(extractDomain(formData.website)) : '';
    const companyHeadline = `${response.title || ''} - ${response.description || ''}`.trim();

    const address = response.location?.address || '';
    const city = response.location?.city || '';
    const state = response.location?.state || '';
    const postalCode = response.location?.postal_code || '';
    const country = response.location?.country || 'United States';

    // Commented here because I'll change for always get information using AI
    // check if one of the location fields is empty
    // if (address === '' || city === '' || state === '' || postalCode === '' || country === '') {
    //   log.warn("Location fields are empty, trying getting it again.");
    //   if (response.original_response && Object.keys(response.original_response).length > 0) {
    //     GetAddressByAI(response.original_response)
    //   } else {
    //     log.warn("No original_response to try get address again")
    //   }
    // }

    const updatedSocials = {
      facebook: response.social_links?.facebook || '',
      instagram: response.social_links?.instagram || '',
      twitter: response.social_links?.twitter || '',
      whatsapp: response.social_links?.whatsapp || '',
      linkedin: response.social_links?.linkedin || '',
      youtube: response.social_links?.youtube || '',
      tiktok: response.social_links?.tiktok || '',
      snapchat: response.social_links?.snapchat || '',
      pinterest: response.social_links?.pinterest || '',
      reddit: response.social_links?.reddit || '',
      telegram: response.social_links?.telegram || '',
      wechat: response.social_links?.wechat || '',
      kwai: response.social_links?.kwai || '',
      tumblr: response.social_links?.tumblr || '',
      orkut: response.social_links?.orkut || ''
    }

    updateFormData({
      ...formData,
      companyName,
      companyHeadline,
      companyFavIcon: response.original_response?.body?.og_images[0] || response.favicon || '',
      companyEmail: emails,
      socials: updatedSocials,
      phoneNumbers: phoneNumbers,

      street: address,
      city: city,
      state: state,
      postalCode: postalCode,
      country: "United States",

      original_response: response.original_response || {},
    });

    auth.updateCompany({
        ...formData,
        companyName,
        companyHeadline,
        companyFavIcon: response.original_response?.body?.og_images[0] || response.favicon || '',
        companyEmail: emails,
        socials: updatedSocials,
        phoneNumbers: phoneNumbers,
        country: "United States",
        original_response: response.original_response || {},
      }
    )

    if (response.original_response && Object.keys(response.original_response).length > 0) {
      setVarResponse(response.original_response.data)
      setShouldGenerateData(true);
      log.debug("varResponse original_response response : ", varResponse);
      log.debug("varResponse original_response response formData : ", formData?.original_response);
    }

    setIsWebsiteChecked(true);
    setBanner2SuccessOpen(true);
    setBanner2ErrorOpen(false);
  }


  // State for form errors
  const [errors, setErrors] = useState({});

  // Generic handle change function for all inputs
  const handleChange = (e) => {
    const {name, value} = e.target;
    log.debug("[HANDLECHANGE] name:", name, "value:", value);

    if (['facebook', 'instagram', 'twitter', 'linkedin', 'youtube', 'whatsapp'].includes(name)) {
      updateFormData({
        ...formData,
        socials: {
          ...formData.socials,
          [name]: value,
        },
      });
    } else {
      updateFormData({
        ...formData,
        [name]: value,
      });
    }

    // if (name === "companyHeadline" && textareaRef.current) {
    //   textareaRef.current.classList.add("flash-effect");
    //   setTimeout(() => {
    //     textareaRef.current.classList.remove("flash-effect");
    //   }, 500);
    // } else if (name === "companyName" && inputRef.current) {
    //   inputRef.current.classList.add("flash-effect");
    //   setTimeout(() => {
    //     inputRef.current.classList.remove("flash-effect");
    //   }, 500);
    // }
  };

  const handleChangeCheckBox = (e) => {
    const {name, checked} = e.target;

    updateFormData({
      ...formData,
      [name]: checked,
    });
  };

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.companyName || formData.companyName.trim() === '') {
      newErrors.companyName = true;
      isValid = false;
      log.error("Field 'companyName' is required and cannot be empty.");
    }

    if (!formData.companyHeadline || formData.companyHeadline.trim() === '') {
      newErrors.companyHeadline = true;
      isValid = false;
      log.error("Field 'companyHeadline' is required and cannot be empty.");
    }

    setErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    if (flash) {
      const timer = setTimeout(() => setFlash(false), 500);
      return () => clearTimeout(timer);
    }
  }, [flash]);

  useEffect(() => {
    log.info(">>>>> formData updating...")
    setFlash(true);
  }, [formData.companyHeadline]);


  // Expose validation and submission functions to parent component
  useImperativeHandle(ref, () => ({
    validateForm,
    submitForm: () => {
      if (validateForm()) {
        log.debug("Form is valid, submitting data:", formData);
      }
    },
  }));

  log.debug("checkWebsiteIsLoading: ", checkWebsiteIsLoading);

  if (checkWebsiteIsLoading && enableWebsiteCheck) {
    return <WebsiteChecking website={formData.website}/>;
  }

  return (
    <div className="sm:px-4 py-8">
      <div className="max-w-xl mx-auto">

        {/* BANNER MESSAGE */}
        <Banner2 className="mb-3" type="success" open={banner2SuccessOpen} setOpen={setBanner2SuccessOpen}>
          {t("onboarding.step2.SuccessMessage")}
        </Banner2>
        <Banner2 className="mb-3" type="error" open={banner2ErrorOpen} setOpen={setBanner2ErrorOpen}>
          {t("onboarding.step2.FailureMessage")}
        </Banner2>

        {/* TITLE */}
        <h1 className="text-3xl text-gray-800 dark:text-gray-100 font-bold mb-6">
          {t("onboarding.step2.title")}
        </h1>

        {/* FORM */}
        <form>
          <div className="space-y-4 mb-8">
            {formData.option === 'website' && (
              <>
                {formData.companyName && formData.companyFavIcon && formData.companyHeadline && (
                  <CompanyTileCard
                    key={0}
                    id={0}
                    name={formData.companyName}
                    image={formData.companyFavIcon}
                    link=""
                    location={formData.website}
                    content={formData.companyHeadline}
                    ref={inputRef}
                  />
                )}

                <div>
                  <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="website">
                    Website
                  </label>
                  <input
                    id="website"
                    name="website"
                    className="form-input w-full"
                    type="text"
                    value={formData.website}
                    onChange={handleChange}
                    disabled={true}
                  />

                </div>

              </>
            )}

            {/* Company Name */}
            <div>
              <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="companyName">
                {t("onboarding.step2.CompanyName")} <span className="text-red-500">*</span>
              </label>
              <input
                id="companyName"
                name="companyName"
                className={`form-input w-full text-black ${errors.companyName ? 'border-red-500' : ''}`}
                type="text"
                value={formData.companyName}
                onChange={handleChange}
                autoComplete="companyName"
              />
              {errors.companyName && <p className="text-red-500">{t("onboarding.step2.CompanyNameError")}</p>}
            </div>

            {/* Company Description */}
            <div>
              <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="companyHeadline">
                {formData.option !== "website" ? t("onboarding.step2.CompanyHeadlineNoWebsite") : t("onboarding.step2.CompanyHeadline")}
                <span className="text-red-500">*</span>
              </label>
              <textarea
                id="companyHeadline"
                name="companyHeadline"
                className={`form-textarea text-xs w-full p-3 pt-1 border border-gray-300 rounded break-words ${errors.companyName ? 'border-red-500' : ''} ${flash ? 'flash' : ''}`}
                type="text"
                rows="3"
                ref={textareaRef}
                value={formData.companyHeadline}
                onChange={handleChange}
                disabled={false}
              />
              {errors.companyHeadline && <p className="text-red-500">{t("onboarding.step2.CompanyHeadlineError")}</p>}
            </div>


            {/* Social Media Fields */}
            {['facebook', 'instagram', 'twitter', 'linkedin', 'youtube', 'whatsapp'].map((field) => (
              <div key={field}>
                <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor={field}>
                  {capitalize(field)}
                </label>
                <input
                  id={field}
                  name={field}
                  className="form-input w-full text-black"
                  type="text"
                  value={formData.socials[field]}
                  onChange={handleChange}
                  // placeholder={`https://${field}.com/yourprofile`}
                  autoComplete={field}
                />
              </div>
            ))}

            {/* Website Technology */}
            <div>
              <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="websiteTechnology">
                {t("onboarding.step2.WebsiteTechnology")}
              </label>
              <select
                id="websiteTechnology"
                name="websiteTechnology"
                className={`form-select w-full text-black ${errors.websiteTechnology ? 'border-red-500' : ''}`}
                value={formData.websiteTechnology}
                onChange={handleChange}
              >
                <option value="Other">{t("other")}</option>
                <option value="Wordpress">WordPress</option>
                <option value="Wix">Wix</option>
                <option value="Shopify">Shopify</option>
              </select>
              {errors.websiteTechnology && <p className="text-red-500">Please select a website technology.</p>}
            </div>

            {/* GOOGLE BUSINESS */}
            <div className="flex items-center">
              <input
                id="googleBusiness"
                name="googleBusiness"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                checked={formData.googleBusiness}
                onChange={handleChangeCheckBox}
              />
              <label htmlFor="googleBusiness"
                     className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
                {t("onboarding.step2.GoogleBusiness")}
              </label>
            </div>

          </div>
        </form>
      </div>
    </div>
  )
    ;
});

export default Step2Form;
