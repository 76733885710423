import React, {useState} from "react";
import {useForm} from "react-hook-form";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import {useAuth} from "./../util/auth";
import {useRouter} from "../util/router";
import {useTranslation} from "react-i18next";

import log from "../util/logger";

function AuthForm(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  const auth = useAuth();
  const router = useRouter();
  const token_reset_email = router.query.token;

  const [pending, setPending] = useState(false);
  const {handleSubmit, register, errors, getValues} = useForm();

  const submitHandlersByType = {
    signin: ({email, pass}) => {
      return auth.signin(email, pass).then((user) => {
        // Call auth complete handler
        props.onAuth(user);
      });
    },
    signup: ({email, pass}) => {
      return auth.signup(email, pass).then((user) => {
        // Call auth complete handler
        props.onAuth(user);
      });
    },
    forgotpass: ({email}) => {
      return auth.sendPasswordResetEmail(email).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: t("password_reset_email_sent"),
        });
      });
    },
    changepass: ({pass}) => {
      return auth.confirmPasswordReset(token_reset_email, pass).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: t("password_changed"),
        });
      });
    },
  };

  // Handle form submission
  const onSubmit = ({email, pass}) => {
    // Show pending indicator
    setPending(true);

    // Call submit handler for auth type
    submitHandlersByType[props.type]({
      email,
      pass,
    }).catch((error) => {
      log.error("Error:", error);
      setPending(false);
      // Show error alert message
      props.onFormAlert({
        type: "error",
        message: error?.message === "Incorrect email or password" ? t("incorrect_email_or_password") : error.message,
      });
    });
  };

  return (
    <form className="space-y-3 " onSubmit={handleSubmit(onSubmit)}>
      {["signup", "signin", "forgotpass"].includes(props.type) && (
        <TextField
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          className="text-sm font-medium text-gray-900"
          error={errors.email}
          inputRef={register({
            required: t("email_enter"),
          })}
        />
      )}

      {["signup", "signin", "changepass"].includes(props.type) && (
        <TextField
          type="password"
          id="pass"
          name="pass"
          placeholder={t("password")}
          className="text-sm font-medium text-gray-900"
          error={errors.pass}
          inputRef={register({
            required: t("password_enter"),
          })}
        />
      )}

      {["signup", "changepass"].includes(props.type) && (
        <TextField
          type="password"
          id="confirmPass"
          name="confirmPass"
          placeholder={t("password_confirm")}
          className="text-sm font-medium text-gray-900"
          error={errors.confirmPass}
          inputRef={register({
            required: t("password_enter_again"),
            validate: (value) => {
              if (value === getValues().pass) {
                return true;
              } else {
                return t("password_doesnt_match");
              }
            },
          })}
        />
      )}

      <Button type="submit" size="lg" disabled={pending} isBlock={true}>
        {pending && <LoadingIcon className="w-6"/>}

        {!pending && <>{props.buttonAction}</>}
      </Button>
    </form>
  );
}

export default AuthForm;
