import {useQuery} from '@tanstack/react-query';
import {getLocalStorageData, setLocalStorageData} from './util';
import api from '../api';
import log from './logger';


export class GenerateRequestModel {
  constructor(prompt, maxTokens, api, websiteUrl, type, refresh = false) {
    this.prompt = prompt; // String: the text prompt for the AI to generate
    this.max_tokens = maxTokens; // Number: maximum number of tokens to generate
    this.api = api; // String: which AI model to use (e.g., "openai")
    this.website_url = websiteUrl; // String: website URL associated with the request
    this.type = type; // String: the type of content to generate
    this.refresh = false; // Boolean: whether to refresh the cache
  }
}

// ############################################################################################################
// ############## AI POST GENERATOR ######################################################################
export const usePostGenerator = (defaultCompany,
                                 currentLanguage,
                                 onSuccess,
                                 onError,
                                 isSuccess,
                                 enabled = true,) => {
  const query_name = 'usePostGenerator';
  // log.debug(`${query_name} > defaultCompany: `, defaultCompany);
  return useQuery(
    [`${query_name}`, `${defaultCompany.website_url}-post-${defaultCompany?.ideaHash}`],
    () => {
      const prompt = `
    ## This report should be written in: ${currentLanguage || 'English'}
    ## You should generate this post based on the idea: ${defaultCompany.post_idea || ''}
    ${defaultCompany.social_network_recommended ? "## This content should be written for the social network [social_network_recommended]: " + defaultCompany.social_network_recommended : ''}
    ## Business name: ${defaultCompany.name || defaultCompany.companyName || ''}
    ## Business Website: ${defaultCompany.website_url || ''}
    ## Company Headline: ${defaultCompany.description || defaultCompany.companyHeadline || ''}
    ## Company location: ${defaultCompany.location || ''}
    ## Keywords: ${defaultCompany.keywords || ''}
    ## Business information miscellaneous: ${defaultCompany?.scrape_data || null}
    `;
      log.debug(`${query_name} > prompt: `, prompt);
      let requestModel5 = new GenerateRequestModel(prompt, 2000, 'anthropic', defaultCompany.website_url, query_name);
      return api.fetchGeneratedData2(requestModel5);
      // return api.fetchGeneratedData2(prompt.trim(), defaultCompany.website_url, query_name);
    },
    {
      staleTime: 120 * 60 * 1000, // 120 minutes
      cacheTime: 100 * 60 * 1000,  // 2 minutes
      refetchOnWindowFocus: false,
      // initialData: () => {
      //   const localData = getLocalStorageData(`${query_name}-${defaultCompany.website_url}-post-${defaultCompany?.ideaHash}`);
      //   const ret = localData !== null && localData !== undefined && localData !== '' ? localData : undefined;
      //   log.debug(`${query_name} > local storage initialData: `, ret);
      //   return ret;
      // },
      onSuccess: (resp) => {
        log.debug(`${query_name} > response`, resp);
        // log.debug(`${query_name} > data stored in local storage`);
        // setLocalStorageData(`${query_name}-${defaultCompany.website_url}-post-${defaultCompany?.ideaHash}`, resp);
        if (onSuccess) onSuccess(resp);
      },
      onError: (err) => {
        log.error(`${query_name} `, err);
        if (onError) onError(err);
      },
      enabled
    }
  );
};

// ############################################################################################################
// ############## AI AIDA FRAMEWORK ######################################################################
export const useAIDAFramework = (defaultCompany,
                                 currentLanguage,
                                 onSuccess,
                                 onError,
                                 isSuccess,
                                 enabled = true) => {
  const query_name = 'useAIDAFramework';
  // log.debug(`${query_name} > defaultCompany: `, defaultCompany);
  return useQuery(
    [`${query_name}`, defaultCompany.website_url],
    () => {
      const prompt = `
    ## This report should be written in: ${currentLanguage || 'English'}
    ## Business name: ${defaultCompany.name || defaultCompany.companyName || ''}
    ## Business Website: ${defaultCompany.website_url || ''}
    ## Company Headline: ${defaultCompany.description || defaultCompany.companyHeadline || ''}
    ## Company location: ${defaultCompany.location || ''}
    ## Keywords: ${defaultCompany.keywords || ''}
    ## Business information miscellaneous: ${defaultCompany?.scrape_data || null}
    `;
      log.debug(`${query_name} > prompt: `, prompt);
      let requestModel4 = new GenerateRequestModel(prompt, 2000, 'openai', defaultCompany.website_url, query_name);
      return api.fetchGeneratedData2(requestModel4);
      // return api.fetchGeneratedData2(prompt.trim(), defaultCompany.website_url, query_name);
    },
    {
      staleTime: Infinity, // Data never goes stale
      cacheTime: Infinity, // Data stays in cache forever
      refetchOnWindowFocus: false,
      initialData: () => {
        const localData = getLocalStorageData(`${query_name}-${defaultCompany.website_url}`);
        const ret = localData !== null && localData !== undefined && localData !== '' ? localData : undefined;
        log.debug(`${query_name} > local storage initialData: `, ret);
        return ret;
      },
      onSuccess: (resp) => {
        log.debug(`${query_name} > response`, resp);
        log.debug(`${query_name} > data stored in local storage`);
        setLocalStorageData(`${query_name}-${defaultCompany.website_url}`, resp);
        if (onSuccess) onSuccess(resp);
      },
      onError: (err) => {
        log.error(`${query_name} `, err);
        if (onError) onError(err);
      },
      enabled
    }
  );
};

// ############################################################################################################
// ############## AI CONTENT MIX ######################################################################
export const useContentMix = (defaultCompany,
                              currentLanguage,
                              onSuccess,
                              onError,
                              isSuccess,
                              enabled = true) => {
  const query_name = 'useContentMix';
  // log.debug(`${query_name} > defaultCompany: `, defaultCompany);
  return useQuery(
    [`${query_name}`, defaultCompany.website_url],
    () => {
      const prompt = `
      ## This report should be written in: ${currentLanguage || 'English'} , including translate the Content Mix description
      
      ## The social network recommended plataform [social_network_recommended] should be random choose restrict for this list: ${defaultCompany.socialPlataformList || ''}
      
      ## Business Profile: ${defaultCompany.content_mix_profile || ''}
      ## Business name: ${defaultCompany.name || defaultCompany.companyName || ''}
      ## Business Website: ${defaultCompany.website_url || ''}
      ## Company Headline: ${defaultCompany.description || defaultCompany.companyHeadline || ''}
      ## Company location: ${defaultCompany.location || ''}
      ## Keywords: ${defaultCompany.keywords || ''}
      
      ## Business information miscellaneous: ${defaultCompany?.scrape_data || null}
    `;
      log.debug(`${query_name} > prompt: `, prompt);
      let requestModel5 = new GenerateRequestModel(prompt, 2000, 'openai', defaultCompany.website_url, query_name);
      return api.fetchGeneratedData2(requestModel5);
      // return api.fetchGeneratedData2(prompt.trim(), defaultCompany.website_url, query_name);
    },
    {
      staleTime: Infinity, // Data never goes stale
      cacheTime: Infinity, // Data stays in cache forever
      refetchOnWindowFocus: false,
      initialData: () => {
        const localData = getLocalStorageData(`${query_name}-${defaultCompany.website_url}`);
        const ret = localData !== null && localData !== undefined && localData !== '' ? localData : undefined;
        log.debug(`${query_name} > local storage initialData: `, ret);
        return ret;
      },
      onSuccess: (resp) => {
        log.debug(`${query_name} > response`, resp);
        log.debug(`${query_name} > data stored in local storage`);
        setLocalStorageData(`${query_name}-${defaultCompany.website_url}`, resp);
        if (onSuccess) onSuccess(resp);
      },
      onError: (err) => {
        log.error(`${query_name} `, err);
        if (onError) onError(err);
      },
      enabled
    }
  );
};

// ############################################################################################################
// ############## AI IDEAS ######################################################################
export const usePostIdeas = (defaultCompany,
                             currentLanguage,
                             onSuccess,
                             onError,
                             isSuccess,
                             enabled = true) => {
  const query_name = 'usePostIdeas';
  // log.debug(`${query_name} > defaultCompany: `, defaultCompany);
  return useQuery(
    [`${query_name}`, defaultCompany.website_url],
    () => {
      const prompt = `
    ## This report should be written in: ${currentLanguage || 'English'}
    ## Business name: ${defaultCompany.name || defaultCompany.companyName || ''}
    ## Company Headline: ${defaultCompany.description || defaultCompany.companyHeadline || ''}
    ## Company location: ${defaultCompany.location || ''}
    ## Keywords: ${defaultCompany.keywords || ''}
    ## Business information miscellaneous: ${defaultCompany?.scrape_data || null}
    `;
      log.debug(`${query_name} > prompt: `, prompt);
      let requestModel4 = new GenerateRequestModel(prompt, 2000, 'openai', defaultCompany.website_url, query_name);
      return api.fetchGeneratedData2(requestModel4);
      // return api.fetchGeneratedData2(prompt.trim(), defaultCompany.website_url, query_name);
    },
    {
      staleTime: Infinity, // Data never goes stale
      cacheTime: Infinity, // Data stays in cache forever
      refetchOnWindowFocus: false,
      initialData: () => {
        const localData = getLocalStorageData(`${query_name}-${defaultCompany.website_url}`);
        const ret = localData !== null && localData !== undefined && localData !== '' ? localData : undefined;
        log.debug(`${query_name} > local storage initialData: `, ret);
        return ret;
      },
      onSuccess: (resp) => {
        log.debug(`${query_name} > response`, resp);
        log.debug(`${query_name} > data stored in local storage`);
        setLocalStorageData(`${query_name}-${defaultCompany.website_url}`, resp);
        if (onSuccess) onSuccess(resp);
      },
      onError: (err) => {
        log.error(`${query_name} `, err);
        if (onError) onError(err);
      },
      enabled
    }
  );
};

// ############################################################################################################
// ############## AI KEYWORDS ANALYSIS ######################################################################
export const useKeywordsAnalysis = (defaultCompany,
                                    currentLanguage,
                                    onSuccess,
                                    onError,
                                    isSuccess,
                                    enabled = true) => {
  const query_name = 'useKeywordsAnalysis';
  // log.debug(`${query_name} > defaultCompany: `, defaultCompany);
  return useQuery(
    [`${query_name}`, defaultCompany.website_url],
    () => {
      const prompt = `
        ## This report should be write in: ${currentLanguage} 
        ## Business name: ${defaultCompany.name || defaultCompany.companyName}
        ## Company Headline: ' + ${defaultCompany.description || defaultCompany.companyHeadline}
        ## Company location: ' + ${defaultCompany.location}
        ## Business information miscellaneous: ${defaultCompany?.scrape_data || null}
        `;
      log.debug(`${query_name} > prompt: `, prompt);
      let requestModel3 = new GenerateRequestModel(prompt, 2000, 'openai', defaultCompany.website_url, query_name);
      return api.fetchGeneratedData2(requestModel3);
      // return api.fetchGeneratedData2(prompt, defaultCompany.website_url, query_name);
    },
    {
      staleTime: Infinity, // Data never goes stale
      cacheTime: Infinity, // Data stays in cache forever
      refetchOnWindowFocus: false,
      initialData: () => {
        const localData = getLocalStorageData(`${query_name}-${defaultCompany.website_url}`);
        const ret = localData !== null && localData !== undefined && localData !== '' ? localData : undefined;
        log.debug(`${query_name} > local storage initialData: `, ret);
        return ret;
      },
      onSuccess: (resp) => {
        log.debug(`${query_name} > response`, resp);
        log.debug(`${query_name} > data stored in local storage`);
        setLocalStorageData(`${query_name}-${defaultCompany.website_url}`, resp);
        if (onSuccess) onSuccess(resp);
      },
      onError: (err) => {
        log.error(`${query_name} `, err);
        if (onError) onError(err);
      },
      enabled
    }
  );
};


// ############################################################################################################
// ############## AI WEBSITE ANALYSIS ######################################################################
export const useWebsiteReport = (defaultCompany,
                                 currentLanguage,
                                 onSuccess,
                                 onError,
                                 isSuccess,
                                 enabled = true) => {
  const query_name = 'useWebsiteReport';
  log.debug(`${query_name} > defaultCompany: `, defaultCompany);
  return useQuery(
    [`${query_name}`, defaultCompany.website_url],
    () => {
      const prompt = 'Business name: ' + (defaultCompany.name || defaultCompany.companyName)
        + ', \n ## Company Headline: ' + (defaultCompany.description || defaultCompany.companyHeadline)
        + ', \n ## Company location: ' + defaultCompany.location
        + `, \n ## This report should be write in: ${currentLanguage} `
        + " \n ## Business information miscellaneous:  " + (defaultCompany?.scrape_data || null)
      log.debug(`${query_name} > prompt: `, prompt);
      let requestModel2 = new GenerateRequestModel(prompt, 2000, 'openai', defaultCompany.website_url, query_name);
      return api.fetchGeneratedData2(requestModel2);
      // return api.fetchGeneratedData2(prompt, defaultCompany.website_url, query_name);
    },
    {
      staleTime: Infinity, // Data never goes stale
      cacheTime: Infinity, // Data stays in cache forever
      refetchOnWindowFocus: false,
      initialData: () => {
        const localData = getLocalStorageData(`${query_name}-${defaultCompany.website_url}`);
        const ret = localData !== null && localData !== undefined && localData !== '' ? localData : undefined;
        log.debug(`${query_name} > local storage initialData: `, ret);
        return ret;
      },
      onSuccess: (resp) => {
        log.debug(`${query_name} > AI_website_SEO`, resp);
        log.debug(`${query_name} > data stored in local storage`);
        setLocalStorageData(`${query_name}-${defaultCompany.website_url}`, resp);
        if (onSuccess) onSuccess(resp);
      },
      onError: (err) => {
        log.error(`${query_name} `, err);
        if (onError) onError(err);
      },
      enabled
    }
  );
};

// ############################################################################################################
// ############## AI INITIAL REPORT DATA ######################################################################
export const useAIInitialReportData = (defaultCompany,
                                       currentLanguage,
                                       onSuccess,
                                       onError,
                                       enabled = true) => {
  const query_name = 'useAIInitialReportData';
  return useQuery(
    [`${query_name}`, defaultCompany.website_url],
    () => {
      const prompt = 'Business name: ' + (defaultCompany.name || defaultCompany.companyName)
        + ', \n ## Company Headline: ' + (defaultCompany.description || defaultCompany.companyHeadline)
        + ', \n ## Company location: ' + defaultCompany.location
        + `, \n ## This report should be write in: ${currentLanguage} `
        + " \n ## Business information miscellaneous:  " + (defaultCompany?.scrape_data || null)
      log.debug("useAIInitialReportData > prompt: ", prompt);
      let requestModel = new GenerateRequestModel(prompt, 2000, 'openai', defaultCompany.website_url, query_name);
      return api.fetchGeneratedData2(requestModel);
    },
    {
      staleTime: Infinity, // Data never goes stale
      cacheTime: Infinity, // Data stays in cache forever
      refetchOnWindowFocus: false,
      initialData: () => {
        const localData = getLocalStorageData(`${query_name}-${defaultCompany.website_url}`);
        const ret = localData !== null && localData !== undefined && localData !== '' ? localData : undefined;
        log.debug(`${query_name} > local storage initialData: `, ret);
        return ret;
      },
      onSuccess: (resp) => {
        log.debug(`${query_name} > AI_website_SEO`, resp);
        log.debug(`${query_name} > data stored in local storage`);
        setLocalStorageData(`${query_name}-${defaultCompany.website_url}`, resp);
        if (onSuccess) onSuccess(resp);
      },
      onError: (err) => {
        log.error(`${query_name} `, err);
        if (onError) onError(err);
      },
      enabled
    }
  );
};