import {useEffect, useRef} from "react";
// import fakeAuth from "fake-auth";
import ApiClient from "../api/ApiClient";
import log from "./logger";

import isUrlHttp from "is-url-http";
import murmurhash from "murmurhash";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";
const api = new ApiClient(API_URL)

export function getSocialPlatformNames(data) {
  if (!data.socials || !Array.isArray(data.socials)) {
    return []; // Return an empty array if socials is not present or not an array
  }
  const socialPlatforms = data.socials.map(social => social.platform_name);
  log.debug("[getSocialPlatformNames] socialPlatforms:", socialPlatforms);
  return socialPlatforms
}

const generateHashes = (array) => {
  return array.map((item) => {
    const combinedText = `${item.idea}${item.summary}${item.URL}`;
    const hash = murmurhash.v3(combinedText);
    return {...item, hash};
  });
};


export function getRandomEmoji() {
  const emojis = [
    '😀', '😂', '🥰', '😎', '🤔', '🤯', '😴', '🤗', '😇', '😍', '🥳', '🤪',
    '🥶', '🤮', '😱', '🥺', '💀', '👻', '🎃', '😻', '🦄', '🐱', '🐶', '🍕',
    '🍔', '🍩', '🍿', '☕', '🍷', '🥂', '🌞', '🌝', '🌈', '🔥', '✨', '⚡',
    '💥', '🌍', '🌌', '🎈', '🎉', '🎊', '🎁', '📚', '💻', '📱', '📷', '🎸'
  ];
  const randomIndex = Math.floor(Math.random() * emojis.length);
  return emojis[randomIndex];
}

export function removeImageTagFromMarkdown(markdown) {
  return markdown.replace(/!\[[^\]]*\]\(data:image\/[^\)]+\)/g, '');
}

export function removeCodeBlockMarksFromMarkdown(markdown) {
  return markdown.replace(/```[a-z]*\s?|```/gi, '');
}

export const languageMap = {
  en: 'English',
  pt: 'Brazilian Portuguese',
  es: 'Spanish',
  fr: 'French',
  // Add more languages as needed
};
//
// export const cleanData = (data) => {
//   if (!data) {
//     return null;
//   }
//   return removeCodeBlockMarksFromMarkdown(data.slice(1, -1).replace(/\\n/g, '\n')).replace(/\\(?=\n)/g, '')
//   // return removeCodeBlockMarksFromMarkdown(data.slice(1, -1))
// }

export const cleanData = (str) => {
  if (!str) {
    return null;
  }
  // Only slice if the string starts and ends with quotes
  const withoutQuotes = (str.startsWith('"') && str.endsWith('"'))
    ? str.slice(1, -1)
    : str;

  return removeCodeBlockMarksFromMarkdown(
    withoutQuotes.replace(/\\n/g, '\n')
  ).replace(/\\(?=\n)/g, '');
};

export const getFullLanguageName = (languageCode) => {
  return languageMap[languageCode] || 'English';
};

export function convertStringToJson(input) {
  try {
    let jsonString;
    if (typeof input === 'string') {
      jsonString = input.replace(/\n/g, '');
    } else if (typeof input === 'object') {
      log.info("[convertStringToJson] input already is a object", input);
      return input; // If input is already a JSON object, return it directly
    } else {
      throw new TypeError('Input must be a string or a JSON object');
    }

    log.info("[convertStringToJson] jsonString:", jsonString);
    const jsonObject = JSON.parse(jsonString);
    log.debug("[convertStringToJson] jsonObject:", jsonObject);
    return jsonObject;
  } catch (error) {
    log.error("ERROR!!! Invalid JSON string", error);
    return null;
  }
}

export function cleanApiResponse(response) {
  try {
    // Step 1: Remove unwanted whitespace and escape characters (like \n)
    const cleanedString = response.replace(/\\n/g, '').trim();

    // Step 2: Parse the string into a JSON object (assumes response is a JSON string)
    const parsedResponse = JSON.parse(cleanedString);

    return parsedResponse;
  } catch (error) {
    log.error("Failed to clean API response:", error);
    return null; // Return null if parsing fails
  }
}

export function getLocalStorageData(key) {
  const storedData = localStorage.getItem(key);
  return storedData ? JSON.parse(storedData) : null;
};

export function setLocalStorageData(key, data) {
  if (data === null || data === undefined) {
    localStorage.removeItem(key);
    return;
  }
  localStorage.setItem(key, JSON.stringify(data));
};

export function removeLocalStorageData(key) {
  return localStorage.removeItem(key);
}

export function urlClean(url) {
  try {
    // Check if the URL has a protocol (http:// or https://). If not, add "http://"
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'http://' + url; // Add default protocol for consistency
    }

    // Create a new URL object
    const parsedUrl = new URL(url);

    // Return the cleaned URL with just the hostname (remove path, query, and hash)
    return parsedUrl.hostname;
  } catch (error) {
    // If the input is not a valid URL, return an empty string or handle as needed
    return '';
  }
}


// // Example usage:
// console.log(extractDomain('https://www.sunokrom.com'));         // Output: "sunokrom"
// console.log(extractDomain('www.sunokrom.com'));                 // Output: "sunokrom"
// console.log(extractDomain('sunokrom.com'));                     // Output: "sunokrom"
// console.log(extractDomain('https://www.reparotecnico.com.br')); // Output: "reparotecnico"
// console.log(extractDomain('https://example.co.uk'));            // Output: "example"
// console.log(extractDomain('https://www.google.com'));           // Output: "google"
// console.log(extractDomain('https://nourish-vida.com'));         // Output: "nourish-vida"
export function extractDomain(url) {
  try {
    // Check if the URL has a protocol (http:// or https://). If not, add "http://" by default
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'http://' + url;
    }

    // Create a URL object to easily access the hostname
    let hostname = new URL(url).hostname;

    // Remove 'www.' prefix if present
    if (hostname.startsWith('www.')) {
      hostname = hostname.slice(4);
    }

    // Split hostname by '.' to handle different domain parts
    const domainParts = hostname.split('.');

    // Known multi-level TLDs that need special handling
    const commonTLDs = ['co.uk', 'com.br', 'org.uk', 'net.au', 'com.au'];

    // Check if the domain ends with one of the common multi-level TLDs
    const lastTwoParts = domainParts.slice(-2).join('.');
    if (commonTLDs.includes(lastTwoParts)) {
      return domainParts.slice(-3, -2)[0]; // Return second-to-last part for multi-level TLDs
    }

    // For standard domains, return the second-to-last part
    return domainParts.slice(-2, -1)[0];
  } catch (error) {
    return ''; // Return empty string if there's an invalid URL
  }
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Clean up markdown string and convert to JSON format
export function markdownStrip(markdownString) {
  // Step 1: Strip Markdown tags
  const strippedString = markdownString
    .replace(/```json/g, '')
    .replace(/```/g, '') // Code blocks
    // .replace(/```$/, '');
    .replace(/(\*\*|__)(.*?)\1/g, '$2') // Bold
    .replace(/(\*|_)(.*?)\1/g, '$2') // Italic
    .replace(/~~(.*?)~~/g, '$1') // Strikethrough
    .replace(/`([^`]+)`/g, '$1') // Inline code
    .replace(/!\[.*?\]\(.*?\)/g, '') // Images
    .replace(/\[(.*?)\]\(.*?\)/g, '$1') // Links
    .replace(/^\s*#\s+/gm, '') // Headers
    .replace(/^\s*##\s+/gm, '') // Sub-headers
    .replace(/^\s*###\s+/gm, '') // Sub-sub-headers
    .replace(/^\s*####\s+/gm, '') // Sub-sub-sub-headers
    .replace(/^\s*#####\s+/gm, '') // Sub-sub-sub-sub-headers
    .replace(/^\s*######\s+/gm, '') // Sub-sub-sub-sub-sub-headers
    .replace(/^\s*>\s+/gm, '') // Blockquotes
    .replace(/^\s*-\s+/gm, '') // Unordered lists
    .replace(/^\s*\d+\.\s+/gm, '') // Ordered lists
    .replace(/^\s*---\s*$/gm, '') // Horizontal rules
    .replace(/^\s*___\s*$/gm, '') // Horizontal rules
    .replace(/^\s*===\s*$/gm, '') // Horizontal rules
    .replace(/\n{2,}/g, '\n') // Multiple newlines to single newline
    .trim();

  // Step 2: Convert to JSON format
  const jsonString = JSON.stringify({content: strippedString});

  // Convert jsonString to JSON object
  const jsonObject = JSON.parse(jsonString);

  return jsonObject;
}

function isValidUrl(str) {
  const pattern = new RegExp(
    '^([a-zA-Z]+:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return pattern.test(str);
}

export function checkIsValidURL(url) {
  log.debug("[checkIsValidURL] url:", url);
//   if url is empty, return false
  if (!url) {
    return false;
  }
  // Check if the URL has a protocol (http:// or https://). If not, add "http://"
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'http://' + url;
  }

  if (!isValidUrl(url)) {
    log.debug("[checkIsValidURL] Invalid URL: invalid URL");
    return false;
  }

  return isUrlHttp(url);
}

export function checkIsValidURL2(url) {
  log.debug("[checkIsValidURL] url:", url);
  try {
    // If URL starts with "www." it need to have 2 dots (to prevent cases like "www.example")
    if (url.startsWith('www.') && url.split('.').length < 3) {
      log.debug("[checkIsValidURL] Invalid URL: no dot after www.");
      return false;
    }

    // Check if the URL has a protocol (http:// or https://). If not, add "http://"
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'http://' + url;
    }

    // Create a URL object
    const parsedUrl = new URL(url);

    // Ensure the hostname contains at least one dot (to prevent cases like "https://asdf")
    const hostname = parsedUrl.hostname;
    if (!hostname.includes('.') || hostname.startsWith('.')) {
      log.debug("[checkIsValidURL] Invalid URL: no dot in hostname");
      return false;
    }

    // If the protocol is not HTTP or HTTPS, consider the URL invalid
    if (parsedUrl.protocol !== 'http:' && parsedUrl.protocol !== 'https:') {
      log.debug("[checkIsValidURL] Invalid URL: invalid protocol");
      return false;
    }

    return true; // If everything passes, the URL is valid
  } catch (error) {
    // If any error occurs (invalid URL), return false
    log.debug("[checkIsValidURL] Invalid URL:", error.message);
    return false;
  }
}


// Make an API request to `/api/{path}`
export function apiRequest(path, method = "GET", data) {
  log.debug("[--- API REQUEST REGULAR ---] path:", path, "method:", method, "data:", data);
  const accessToken = api.getAccessToken();

  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          api.signout();
        }

        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

// Make an API request to any external URL
export function apiRequestExternal(url, method = "GET", data) {
  log.debug("[apiRequestExternal] url:", url, "method:", method, "data:", data);
  return fetch(url, {
    method: method,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then((response) => response.json());
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  log.error("[CustomError] code:", code, "message:", message);
  const error = new Error(message);
  error.code = code;
  return error;
}

// Hook that returns previous value of state
export function usePrevious(state) {
  log.debug("[usePrevious] state:", state);
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}
