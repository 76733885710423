import React, {useState} from "react";
import {useForm} from "react-hook-form";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import {useAuth} from "./../util/auth";
import log from "./../util/logger";
import {useTranslation} from "react-i18next";

function SettingsGeneral(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  log.debug('[SettingsGeneral component] received:', auth.user)

  const {register, handleSubmit, errors} = useForm();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: t("settings.profile_updated"),
        })
        ;
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        type="text"
        id="name"
        name="name"
        placeholder="Name"
        label={t("settings.name")}
        defaultValue={auth.user.name}
        error={errors.name}
        inputRef={register({
          required: t("settings.name_error"),
        })}
      />
      <TextField
        type="email"
        id="email"
        name="email"
        placeholder="Email"
        label={t("settings.email")}
        defaultValue={auth.user.email}
        error={errors.email}
        inputRef={register({
          required: t("settings.email_error"),
        })}
      />
      <Button
        type="submit"
        size="md"
        variant="primary"
        disabled={pending}
        isBlock={true}
      >
        {!pending && <>{t("button_save")}</>}

        {pending && <LoadingIcon className="w-6"/>}
      </Button>
    </form>
  );
}

export default SettingsGeneral;
