import React, {createContext, useContext, useState} from 'react';
import log from './logger';

const FormContext = createContext();

export function useFormContext() {
  return useContext(FormContext);
}

export function FormProvider({children}) {
  const originalState = {
    companyName: "",
    companyHeadline: "",
    industry: "",
    companyFavIcon: "",
    companyEmail: "",
    website: "",
    phoneNumber: "",
    socials: { // Social media links
      facebook: "",
      instagram: "",
      twitter: "",
      whatsapp: "",
      linkedin: "",
      youtube: "",
      tiktok: "",
      snapchat: "",
      pinterest: "",
      reddit: "",
      telegram: "",
      wechat: "",
      kwai: "",
      tumblr: "",
      orkut: ""
    },
    googleBusiness: false,
    websiteTechnology: 'Other',
    phoneNumbers: "",
    street: "",
    city: "",
    state: "",
    postal_code: "",
    country: 'United States',
    option: "",
    original_response: {},
  }

  const [formData, setFormData] = useState(originalState);
  const [isWebsiteChecked, setIsWebsiteChecked] = useState(false);

  const updateFormData = (newData) => {
    setFormData((prevData) => {
      const updatedData = {...prevData, ...newData};
      log.debug('[FormContext] updateFormData called with: ', updatedData);
      log.debug('[FormContext] updated: ', {...prevData, ...updatedData});
      return updatedData;
    });
  };

  const resetFormData = () => {
    setFormData(originalState);
    log.debug('[FormContext] FORMDATA HAS BEEN RESET');
  };

  const updateIsWebsiteChecked = (value) => {
    setIsWebsiteChecked(value);
    log.debug('[FormContext] isWebsiteChecked updated to: ', value);
  };

  return (
    <FormContext.Provider value={{
      formData,
      updateFormData,
      resetFormData,
      isWebsiteChecked,
      setIsWebsiteChecked: updateIsWebsiteChecked
    }}>
      {children}
    </FormContext.Provider>
  );
}
